/* eslint-disable jsx-a11y/anchor-is-valid */
import {useEffect, useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {useFormik} from 'formik'
import {loginWithSeller, resendOTP, verifyOTP} from '../core/_requests'
import {useNavigate} from 'react-router-dom'
import { Toast, ToastContainer } from 'react-bootstrap'


const loginSchema = Yup.object().shape({
  whatsapp_number: Yup.string()
    .matches(/^[1-9][0-9]{9}$/, 'Invalid WhatsApp number')
    .required('WhatsApp number is required'),
})

const initialValues = {
  whatsapp_number: '',
}

export function LoginWithSeller() {
  const [loading, setLoading] = useState(false)
  const [authToken, setAuthToken] = useState<any>('')
  const [objUser, setObjUser] = useState<any>({})
  const [otpSent, setOtpSent] = useState(false)
  const [otp, setOtp] = useState('')
  const [token, setToken] = useState('')

  const sendOTP = async () => {
    try {
      const res = await resendOTP(formik?.values?.whatsapp_number) as any
      console.log(res, 'sent to OTP server : ')
      if (res?.data?.responseStatus === 200) {
        setSessionMessage({
          type: 'success',
          message: res?.data?.responseMessage ?? 'OTP Resend Successfully',
          show: true,
        })
      }else{
        setSessionMessage({
          type: 'danger',
          message: res?.data?.responseMessage ?? 'Something Went Wrong',
          show: true,
        })
      }
      setOtpSent(true)
      setLoading(false)
      // } else if (response?.data?.responseStatus === 404) {
      //   // User not found
      //   setSessionMessage({type: 'danger', message: res?.data?.responseMessage ?? "SomeThing Went Wrong", show: true})
      // } else {
      //   // Other errors
      //   setSessionMessage({type: 'danger', message: res?.data?.responseMessage ?? "SomeThing Went Wrong", show: true})
      // }
    } catch (error) {
      console.error('Error resending OTP:', error)
      setSessionMessage({type: 'danger', message: 'Something went wrong', show: true})
      setLoading(false)
    }
  }

  const [sessionMessage, setSessionMessage] = useState({
    type: '',
    message: '',
    show: false,
  })
  
  const navigate = useNavigate()

  useEffect(() => {
    if (sessionMessage.show) {
      setTimeout(() => {
        setSessionMessage({type: '', message: '', show: false})
      }, 4500)
    }
  }, [sessionMessage.message])

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        if (otpSent) {
          const res: any = await verifyOTP(token, otp)
          if (res?.data?.responseStatus == 200) {
          let firmPersonInfo  = res?.data?.responseData?.firmPersonInfo
          firmPersonInfo.role_id = 1
          // res?.data?.responseData?.firmPersonInfo.role_id = 1
          console.log(res, 'data from verify token: ')
          setAuthToken(res?.data?.responseData?.token ?? '')
          setObjUser(res?.data?.responseData?.firm_person_id ?? {})

          localStorage.setItem('authtoken', res?.data?.responseData?.token)
          localStorage.setItem('objUser', JSON.stringify(firmPersonInfo))
          window.location.replace('/webpage-seller/seller-offer')
          setLoading(false)
          }
          else{
            setSessionMessage({
              type: 'danger',
              message: res?.data?.responseMessage ?? 'Something Went Wrong',
              show: true,
            })
            setLoading(false)
          }
        } else {
          const dataNew: any = await loginWithSeller(values.whatsapp_number)
            .then((res: any) => {
              console.log(res, 'success or not')
              if (res?.data?.responseStatus == 200) {
                console.log(res?.data, 'success or not')
                setToken(res?.data?.responseData?.token ?? '')
                setAuthToken(res?.data?.responseData?.token ?? '')
                setObjUser(res?.data?.responseData?.firm_person_id ?? {})
                sendOTP()
                // setOtpSent(true);
              } else if (res?.data?.responseStatus == 404) {
                setSessionMessage({
                  type: 'danger',
                  message: res?.data?.responseMessage ?? 'User not founds',
                  show: true,
                })
                setLoading(false)
              } else {
                setSessionMessage({
                  type: 'danger',
                  message: res?.data?.responseMessage ?? 'Something Went Wrong',
                  show: true,
                })
                setLoading(false)
              }
            })
            .catch((err: any) => {
              if (err?.response?.status == 429) {
                setSessionMessage({
                  type: 'danger',
                  message: err?.response?.statusText ?? 'Something Went Wrong',
                  show: true,
                })
              } else {
                setSessionMessage({
                  type: 'danger',
                  message: err?.message ?? 'Something Went Wrong',
                  show: true,
                })
              }
              setSubmitting(false)
              setLoading(false)
            })
        }
      } catch (error) {
        console.error(error)
        setLoading(false)
        // saveAuth(undefined)
        // setStatus('The login details are incorrect')
        // setSubmitting(false)
        // setLoading(false)
      }
    },
  })

  return (
    <form
      className='form w-100'
      onSubmit={formik.handleSubmit}
      noValidate
      id='kt_login_signin_form'
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        <h1 className='text-dark fw-bolder mb-3'>Seller Login</h1>
        <div className='text-gray-500 fw-semibold fs-6'>Welcome to Polymart</div>
      </div>

      <div className='fv-row mb-8'>
        <label className='form-label fs-6 fw-bolder text-dark'>WhatsApp Number</label>
        <input
          placeholder='WhatsApp Number'
          {...formik.getFieldProps('whatsapp_number')}
          onKeyPress={(e) => {
            const inputValue = (e.target as HTMLInputElement)?.value + e.key
            const isValidNumber = /^[6-9][0-9]{0,9}$/.test(inputValue)
            if (!isValidNumber) {
              e.preventDefault()
            }
          }}
          className={clsx(
            'form-control bg-transparent',
            {'is-invalid': formik.touched.whatsapp_number && formik.errors.whatsapp_number},
            {
              'is-valid': formik.touched.whatsapp_number && !formik.errors.whatsapp_number,
            }
          )}
          type='text'
          name='whatsapp_number'
          autoComplete='off'
        />
        {formik.touched.whatsapp_number && formik.errors.whatsapp_number && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.whatsapp_number}</span>
            </div>
          </div>
        )}
      </div>

      {/* Conditionally render OTP input field */}
      {otpSent && (
        <div className='fv-row mb-8'>
          <label className='form-label fs-6 fw-bolder text-dark'>OTP</label>
          <input
            placeholder='Enter OTP'
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            className='form-control bg-transparent'
            type='text'
            name='otp'
            autoComplete='off'
          />
        </div>
      )}

      {/* Existing code for submit button */}
      <div className='d-grid mb-10'>
        <button
          type='submit'
          id='kt_sign_in_submit'
          className='btn btn-primary'
          disabled={loading || formik.isSubmitting || (!otpSent && !formik.isValid)}
        >
          {!loading && (
            <span className='indicator-label'>{otpSent ? 'Verify OTP' : 'Get OTP'}</span>
          )}
          {loading && (
            <span className='indicator-progress' style={{display: 'block'}}>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
      </div>

      {otpSent && (
        <button type='button' className='btn btn-link' onClick={sendOTP}>
          Resend OTP
        </button>
      )}
      <ToastContainer className='p-3 position-fixed' position={'top-end'}>
        <Toast
          show={sessionMessage.show}
          onClose={() => {
            setSessionMessage({
              type: '',
              message: '',
              show: false,
            })
          }}
          bg={sessionMessage.type}
        >
          {/* <Toast.Header>Error</Toast.Header> */}
          <Toast.Body className='text-white'>{sessionMessage.message}</Toast.Body>
        </Toast>
      </ToastContainer>
    </form>
  )
}
