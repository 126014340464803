import { StepperComponent } from '../../../_metronic/assets/ts/components'
import { KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import { QueryResponseProvider } from './seller-offers-list/core/QueryResponseProvider'
import { useEffect, useRef, useState } from 'react'
import { createPortal } from 'react-dom'
import { Col, Form, Row, Spinner, Toast, ToastContainer, Modal } from 'react-bootstrap'
import Select from 'react-select'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  createOfferNew,
  getBuyerSeller,
  getSellerData,
} from './seller-offers-list/core/_requests'
import { getAllProduct } from '../inquiry-management/Inquiry-list/core/_requests'
import toast from 'react-hot-toast'
import { useQueryResponse } from './seller-offers-list/core/QueryResponseProvider'

type Props = {
  show: boolean
  handleClose: () => void
  message: any
  setMessge: any
}

const modalsRoot = document.getElementById('root-modals') || document.body

const addOfferValidation = Yup.object().shape({
  source_location_id: Yup.string().required('This Field is required'),
  seller_shipping_address_id: Yup.string().required('This Field is required'),
  addOfferValues: Yup.array().of(
    Yup.object().shape({
      product_id: Yup.string().required('This Field is required'),
      price: Yup.number().required('This Field is required').positive('Must be a positive number.'),
    })
  ),
}) as any

const initialValues = {
  source_location_id: '',
  seller_shipping_address_id: '',
  addOfferValues: [{
    firm_id: '',
    seller_id: '',
    source_location_id: '',
    seller_shipping_address_id: '',
    product_id: '',
    quantity: '',
    price: '',
    validity: '',
    whatsapp_number: '',
  }]
} as any

const AddOfferModal = ({ show, handleClose, message, setMessge }: Props) => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }
  const [loadingList, setLoadingList] = useState(false)
  const [loadingGetData, setLoadingGetData] = useState(false)
  const [loading, setLoading] = useState(false)
  const [lstLocation, setLstLocation] = useState([])
  const [lstProduct, setLstProduct] = useState([])
  const [lstFirmMaster, setLstFirmMaster] = useState([])
  const { refetch } = useQueryResponse()
  const [firmId, setFirmId] = useState('')
  const [primaryCity, setPrimaryCity] = useState('')
  const [sessionMessage, setSessionMessage] = useState({
    type: '',
    message: '',
    show: false,
  })

  useEffect(() => {
    try {
      let user = localStorage.getItem('objUser') as any
      if (JSON.parse(user)) {
        user = JSON.parse(user)
        setFirmId(user?.buyer_seller_id)
      }
    } catch (err: any) {
      console.log(err)
    }
  }, [])


  const getUserDetails = () => {
    try {
      let user = localStorage.getItem('objUser') as any
      if (JSON.parse(user)) {
        user = JSON.parse(user)
        return user
      }
      else {
        return {}
      }
    } catch (err: any) {
      console.log(err)
      return {}
    }
  }

  const getSellerData1 = async () => {
    const sellerData = await getSellerData(firmId)
    if (
      sellerData &&
      sellerData.responseData &&
      sellerData.responseData.buyerSellerPrimaryShippingInfo
    ) {
      const primaryCityData = sellerData.responseData.buyerSellerPrimaryShippingInfo.find(
        (info: any) => info.is_primary === true
      )?.city
      const primaryCityI = sellerData.responseData.buyerSellerPrimaryShippingInfo.find(
        (info: any) => info.is_primary === true
      )?.district_id
      const primaryCityD = sellerData.responseData.buyerSellerPrimaryShippingInfo.find(
        (info: any) => info.is_primary === true
      )?.id
      const cities = sellerData.responseData.buyerSellerPrimaryShippingInfo.map((info: any) => ({
        source_location_id: info.district_id,
        value: info.id,
        label: info.city,
      }))

      setPrimaryCity(primaryCityData)
      setLstLocation(cities)
      formik.setFieldValue('source_location_id', primaryCityI ?? '')
      formik.setFieldValue(
        'seller_shipping_address_id', primaryCityD ?? ''
      )
    }
  }

  const getDataOfBuyerSellerList = async () => {
    try {
      let dataFirm: any = await getBuyerSeller()
      const filteredData = dataFirm.responseData.filter((obj: any) => obj.id === firmId)
      let lstTmpFirm = dataFirm.responseData.map((obj: any) => {
        return { value: obj.id, label: obj.firm_name }
      })
      setLstFirmMaster(dataFirm.responseData)
    } catch (err) {
      console.log(err)
    }
  }

  const getDataOfProductList = async () => {
    try {
      // Product
      let dataProduct: any = await getAllProduct()
      let lstTmpProduct = dataProduct.responseData.map((obj: any) => {
        return { value: obj.id, label: obj.product_variation }
      })
      setLstProduct(lstTmpProduct)
    } catch (err) {
      console.log(err)
    }
  }

  const getFirmList = async () => {
    try {
      setLoadingList(true)
      getDataOfBuyerSellerList()
      getDataOfProductList()
      setLoadingList(false)
    } catch (err) {
      setLoadingList(false)
    }
  }



  const formik = useFormik({
    initialValues,
    validationSchema: addOfferValidation,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      let reqBody = JSON.parse(JSON.stringify(values.addOfferValues))
      reqBody = reqBody.map((x: any) => {
        return {
          status: 1,
          created_source: 'webpage',
          update_source: 'webpage',
          quantity: 21,
          firm_id: getUserDetails()?.buyer_seller_id,
          seller_id: getUserDetails()?.id,
          source_location_id: values.source_location_id,
          seller_shipping_address_id: values.seller_shipping_address_id,
          product_id: x.product_id,
          price: x.price,
          validity: 8,
          whatsapp_number: getUserDetails()?.whatsapp_number,
        }
      })

      setLoading(true)
      try {
        const dataNew: any = await createOfferNew(reqBody)
        if (dataNew.responseStatus == 200) {
          formik.resetForm()
          formik.setFieldValue(`addOfferValues`, [
            {
              firm_id: '',
              seller_id: '',
              source_location_id: '',
              seller_shipping_address_id: '',
              product_id: '',
              quantity: '',
              price: '',
              validity: '',
              whatsapp_number: '',
            }
          ])
          getSellerData1()
          // setTimeout(() => {
          //   refetch()
          // }, 500)
          // setMessge({type: 'success', message: dataNew.responseMessage, show: true})
          setSessionMessage({ type: 'success', message: dataNew.responseMessage, show: true })
          // handleClose()
          setLoading(false)
        } else {
          setSessionMessage({
            type: 'danger',
            message: dataNew?.responseMessage ?? 'SomeThing Went Wrong',
            show: true,
          })
          setLoading(false)
        }
        setTimeout(() => {
          setSessionMessage({
            type: '',
            message: '',
            show: false,
          })
        }, 3500)
      } catch (error) {
        console.error(error)
        setStatus('The login details are incorrect')
        setSubmitting(false)
        setLoading(false)
        setTimeout(() => {
          setSessionMessage({
            type: '',
            message: '',
            show: false,
          })
        }, 3500)
      }
    },
  }) as any


  useEffect(() => {
    formik.validateForm()
  }, [])

  useEffect(() => {
    getFirmList()
    getSellerData1()
    formik.resetForm()
    formik.setFieldValue(`addOfferValues`, [{}])
  }, [firmId, show])

  const successToast = (message: string) => toast.success(message)

  const showOfferValidationError = (i: any, name: any) => {
    try {
      if (formik?.touched?.addOfferValues) {
        let error = formik?.errors ?? {} as any
        let addOfferValues = error?.addOfferValues ?? [] as any
        if (addOfferValues.length > 0) {
          let messageObj = addOfferValues[i as any] ?? {} as any
          if (messageObj) {
            let message = messageObj[name as any] ?? null
            return message
          }
          else {
            return null
          }
        }
        else {
          return null
        }
      }
      else {
        return null
      }
    }
    catch (err: any) {
      return null
    }
  }

  return <div className='row h-100vh py-7' style={{ backgroundImage: `url(${toAbsoluteUrl('/media/misc/achive-bg-1.png')})`, height: "100vh" }}>
    <div className='col-md-2'>  </div>
    <div className='col-md-8'>
      
      <div className='card py-10 mb-10 pt-0'>
        <div className='d-flex border-bottom mb-5 justify-content-center' style={{ background: '#26A69B', borderRadius: '4px 4px 0 0' }}>
          <h1 className='my-4' style={{ fontSize: '45px', color: '#fff' }}> Add Offer </h1>
        </div>
        <div className='card-body'>
          {sessionMessage.show ? <div className={`text-end text-${sessionMessage.type}`}><b>{sessionMessage.message} </b></div> : ""}
          
          <div className="mb-3 d-block d-md-flex justify-content-center mb-7">

            <p className="mb-0 me-5 lh-xl pt-2" style={{ fontSize: '18px', color: '#3f4254', fontWeight: 600 }}>Godown</p>
            <Form.Group style={{ width: '200px' }}>
              <Select
                options={lstLocation}
                className='custom_select'
                classNamePrefix='Select'
                placeholder='Select Godown'
                value={lstLocation.filter((x: any) => x.value == formik.values.seller_shipping_address_id).length > 0 ?
                  lstLocation.find((x: any) => x.value == formik.values.seller_shipping_address_id) : []
                }
                onChange={(e: any) => {
                  const selectedCity: any = lstLocation.find((city: any) => city.value === e.value);
                  formik.setFieldValue('source_location_id', selectedCity ? selectedCity?.source_location_id : '');
                  formik.setFieldValue('seller_shipping_address_id', selectedCity ? selectedCity?.value : '');
                }}
              />

              {formik?.touched?.source_location_id && formik?.errors?.source_location_id && (
                <div className='fv-plugins-message-container'>
                  <span role='alert' className={'text-danger'}>
                    {formik?.errors?.source_location_id}
                  </span>
                </div>
              )}
            </Form.Group>
          </div>
          <div>
            <div>
              {loadingGetData || loadingList ? (
                <div
                  style={{ height: '550px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                >
                  <Spinner animation='border' />
                </div>
              ) : (
                <form
                  className='form w-100'
                  onSubmit={formik.handleSubmit}
                  noValidate
                  id='kt_login_signin_form'
                >
                  {formik.values.addOfferValues.map((x: any, i: any) => <Row className='mb-7 align-items-center prdct-dtl-grd d-block d-md-flex'>
                    <Col md={6} className='mb-3 mb-md-0'>
                      <Form.Group>
                        <Form.Label className='fw-bold required form-label'>Select Grade</Form.Label>
                        <Select
                          options={lstProduct.filter((x: any) => formik?.values?.addOfferValues?.filter((y: any) => x?.value === y?.product_id)?.length > 0 ? false : true)}
                          className='custom_select'
                          classNamePrefix='Select'
                          placeholder='Select Grade'
                          value={x.product_id ? lstProduct.find(
                            (product: any) => product.value === x.product_id
                          ) : []}
                          onChange={(selectedProduct: any) => {
                            formik.setFieldValue(`addOfferValues[${i}]product_id`, selectedProduct ? selectedProduct.value : '')
                          }}
                        />
                        {showOfferValidationError(i, 'product_id') ?
                          <div className='fv-plugins-message-container'>
                            <span role='alert' className={'text-danger'}>
                              {showOfferValidationError(i, 'product_id')}
                            </span>
                          </div>
                          : ""}
                      </Form.Group>
                    </Col>
                    <Col md={6} className='mb-3 mb-md-0'>
                      <Form.Group>
                        <Form.Label className='required form-label fw-bold'> Price/Kg </Form.Label>
                        <Form.Control
                          type='number'
                          className='form-control-solid'
                          placeholder='Price/Kg'
                          value={x.price}
                          onChange={(e: any) => {
                            let decimalPattern = /^\d+(\.\d{0,3})?$/
                            if (decimalPattern.test(e.target.value)) {
                              formik.setFieldValue(`addOfferValues[${i}]price`, e.target.value)
                            } else if (e.target.value == '') {
                              formik.setFieldValue(`addOfferValues[${i}]price`, '')
                            }
                          }}
                        />
                        {showOfferValidationError(i, 'price') ?
                          <div className='fv-plugins-message-container'>
                            <span role='alert' className={'text-danger'}>
                              {showOfferValidationError(i, 'price')}
                            </span>
                          </div>
                          : ""}
                      </Form.Group>
                    </Col>
                    <Col md={6} className='mb-3 mb-md-0'>
                      <div className='d-flex'>
                        <button
                          type='button'
                          onClick={() => {
                            let oldArray = formik.values.addOfferValues
                            oldArray.splice(i + 1, 0, initialValues.addOfferValues[0]);
                            formik.setFieldValue("addOfferValues", oldArray)
                          }}
                          className='btn btn-sm btn-icon mt-8 btn-color-success'
                          disabled={loading}
                        >
                          <KTSVG
                            path='/media/icons/duotune/general/gen035.svg'
                            className='svg-icon-muted svg-icon-2hx me-2'
                          />
                        </button>
                        {formik.values.addOfferValues.length > 1 ?
                          <button
                            type='button'
                            onClick={() => {
                              let oldArray = formik.values.addOfferValues
                              oldArray.splice(i, 1)
                              formik.setFieldValue("addOfferValues", oldArray)
                            }}
                            className='btn btn-sm btn-icon mt-8 btn-color-danger'
                            // style={{visibility: index === 0 ? 'hidden' : 'visible'}}
                            disabled={loading}
                          >
                            <KTSVG
                              path='/media/icons/duotune/general/gen036.svg'
                              className='svg-icon-muted svg-icon-2hx'
                            />
                          </button>
                          : ""}
                      </div>
                    </Col>
                  </Row>
                  )}

                  <div className='d-grid mb-3 d-flex justify-content-center'>
                    <button
                      type='submit'
                      id='kt_sign_in_submit'
                      className='btn btn-primary w-50 py-5 mb-5'
                      disabled={loading}
                    >
                      {!loading && <span className='indicator-label'>{'Add'}</span>}
                      {loading && (
                        <span className='indicator-progress' style={{ display: 'block' }}>
                          Please wait...
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                  <div className='d-grid mt-2 text-left' style={{ fontSize: '15px', color: 'grey' }}>
                    <p className='mb-0'>* The minimum offer quantity should be 25 MT. <br />* Place offers for prime grade only.</p>
                  </div>


                </form>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className='col-md-2'></div>
  </div>
}

export default AddOfferModal
