import axios from 'axios'
import {AuthModel, UserModel} from './_models'

const API_URL = process.env.REACT_APP_API_URL
const API_URL_NEW = process.env.REACT_APP_API_URL_NEW

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const LOGIN_URL = `${API_URL}/login`
export const REGISTER_URL = `${API_URL}/register`
export const REQUEST_PASSWORD_URL = `${API_URL}/forgot_password`
export const LOGINWITHSELLER_URL = `${API_URL_NEW}/buyer-seller/login`
export const RESENDOTP_SELLER_URL = `${API_URL_NEW}/buyer-seller/resend-otp`
export const VERIFYOTP_SELLER_URL = `${API_URL_NEW}/buyer-seller/otp-verify`
// Server should return AuthModel
export function login(email: string, password: string) {
  return axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
  })
}

export function resendOTP(whatsapp_number: string) {
  return axios.post<AuthModel>(RESENDOTP_SELLER_URL, {
    whatsapp_number,
    
  })
}

export function verifyOTP(token: string,otp: string) {
  return axios.post<AuthModel>(VERIFYOTP_SELLER_URL, {
    token,
    otp
  })
}

export function loginWithSeller(whatsapp_number: string) {
  return axios.post<AuthModel>(LOGINWITHSELLER_URL, {
    whatsapp_number,
    type:2
  })
}

export function loginNew(email: string, password: string) {
  return axios.post<any>(`${API_URL_NEW}/user/login`, {
    email,
    password,
  })
}

export function forgotPasswordApi(data: any) {
  return axios.post<any>(`${API_URL_NEW}/user/forgot-password`, 
    data,
  ).then((data:any)=> data.data)
}

export function resetPasswordApi(data: any) {
  return axios.post<any>(`${API_URL_NEW}/user/reset-password`,
    data
  ).then((data:any)=> data.data)
}

export function verifyotpApi(data: any) {
  return axios.post<any>(`${API_URL_NEW}/user/check_2fa_code`,
    data
  ).then((data:any)=> data.data)
}

export function generate2faOTPApi(data: any) {
  return axios.post<any>(`${API_URL_NEW}/user/generate_2fa_otp`,
    data
  ).then((data:any)=> data.data)
}

export function setup2faApi(data: any) {
  return axios.post<any>(`${API_URL_NEW}/user/verify_to_enable_2fa`,
    data
  ).then((data:any)=> data.data)
}

export function disable2faApi(data: any) {
  return axios.post<any>(`${API_URL_NEW}/user/disable_2fa`,
    data
  ).then((data:any)=> data.data)
}

// Server should return AuthModel
export function register(
  email: string,
  firstname: string,
  lastname: string,
  password: string,
  password_confirmation: string
) {
  return axios.post(REGISTER_URL, {
    email,
    first_name: firstname,
    last_name: lastname,
    password,
    password_confirmation,
  })
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string) {
  return axios.post<{result: boolean}>(REQUEST_PASSWORD_URL, {
    email,
  })
}

export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
}

