import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Form, FormCheck, OverlayTrigger, Row, Spinner, Toast, ToastContainer, Tooltip } from 'react-bootstrap'
import { useDropzone } from 'react-dropzone'
import { Link, Navigate, useNavigate, useParams } from 'react-router-dom'
import { KTCardBody, KTSVG, toAbsoluteUrl } from '../../../_metronic/helpers'
import Select from 'react-select'
import SellerGstCertificate from '../seller-management/SellerGstCertificate'
import { useLayout } from '../../../_metronic/layout/core'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import {
  changeBuyerEditHistory,
  downloadBuyerEditHistoryFile,
  getAllGradeGroup,
  getBuyerSellerEditHistoryList,
  getGradeGroupWiseProducts,
  getSingleProductByID,
  createBuyerNew,
  updateBuyerNew,
  getAllStateList,
  getDistrictLocationASC
} from './buyers-list/core/_requests'
import {
  uploadGSTFile,
} from '../seller-management/sellers-list/core/_requests'
import { checkDisplayValue, checkEmptyValue } from '../../utils/utils'
import moment from 'moment'
import "../utils/password.css"

const eprList = [
  { value: '', label: 'Select EPR' },
  { value: 'Confirmed', label: 'Confirmed' },
  { value: 'Applied', label: 'Applied' },
  { value: 'Not Applied', label: 'Not Applied' },
]

const supList = [
  { value: '', label: 'Select SUP' },
  { value: 'Not Applicable', label: 'Not Applicable' },
  { value: 'Applicable', label: 'Applicable' },
  { value: 'To Be Checked', label: 'To be checked ' },
]

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/

const loginSchema = Yup.object().shape({
  category: Yup.string().required('Category is required'),
  epr: Yup.string().required('This Field is required'),
  sup: Yup.string().required('This Field is required'),

  GST_file_name: Yup.string().required('GST file is required'),

  firm_persons: Yup.array().of(
    Yup.object().shape({
      name: Yup.string().when('deleted_at', {
        is: false,
        then: Yup.string().required('This Field is required'),
      }),
      whatsapp_number: Yup.string().when('deleted_at', {
        is: false,
        then: Yup.string()
          .required('Whatsapp number is required')
          .max(12, 'must be less than 12 characters')
          .min(10, 'must be at least 10 characters')
          .matches(phoneRegExp, 'Whatsapp number is not valid'),
      }),
    })
  ),
  billing_address: Yup.array().of(
    Yup.object().shape({
      address_line_1: Yup.string().when('deleted_at', {
        is: false,
        then: Yup.string().required('This Field is required'),
      }),
      address_line_2: Yup.string().when('deleted_at', {
        is: false,
        then: Yup.string().required('This Field is required'),
      }),
      city: Yup.string().when('deleted_at', {
        is: false,
        then: Yup.string().required('This Field is required'),
      }),
      state: Yup.string().when('deleted_at', {
        is: false,
        then: Yup.string().required('This Field is required'),
      }),
      state_id: Yup.string().when('deleted_at', {
        is: false,
        then: Yup.string().required('This Field is required'),
      }),
      district: Yup.string().when('deleted_at', {
        is: false,
        then: Yup.string().required('This Field is required'),
      }),
      district_id: Yup.string().when('deleted_at', {
        is: false,
        then: Yup.string().required('This Field is required'),
      }),
      pincode: Yup.string().when('deleted_at', {
        is: false,
        then: Yup.string()
          .required('This Field is required')
          .matches(/^[0-9]+$/, 'Must be only digits')
          .min(6, 'Must be exactly 6 digits')
          .max(6, 'Must be exactly 6 digits'),
      }),
      gst: Yup.string().when('deleted_at', {
        is: false,
        then: Yup.string().required('This Field is required').min(15, 'Must be exactly 15 character').max(15, 'Must be exactly 15 character'),
      }),
    })
  ),

  shipping_address: Yup.array().of(
    Yup.object().shape({
      address_line_1: Yup.string().when('deleted_at', {
        is: false,
        then: Yup.string().required('This Field is required'),
      }),
      address_line_2: Yup.string().when('deleted_at', {
        is: false,
        then: Yup.string().required('This Field is required'),
      }),
      city: Yup.string().when('deleted_at', {
        is: false,
        then: Yup.string().required('This Field is required'),
      }),
      state: Yup.string().when('deleted_at', {
        is: false,
        then: Yup.string().required('This Field is required'),
      }),
      state_id: Yup.string().when('deleted_at', {
        is: false,
        then: Yup.string().required('This Field is required'),
      }),
      district: Yup.string().when('deleted_at', {
        is: false,
        then: Yup.string().required('This Field is required'),
      }),
      district_id: Yup.string().when('deleted_at', {
        is: false,
        then: Yup.string().required('This Field is required'),
      }),
      pincode: Yup.string().when('deleted_at', {
        is: false,
        then: Yup.string()
          .required('This Field is required')
          .matches(/^[0-9]+$/, 'Must be only digits')
          .min(6, 'Must be exactly 6 digits')
          .max(6, 'Must be exactly 6 digits'),
      }),
    })
  ),
})

const buyerDetailInitialValues = {
  name: "",
  email: "",
  whatsapp_number: "",
  notify_firm_person: 0,
  status: 1,
  verified: 1,
  is_primary: false,
  deleted_at: false
}

const billingAddressInitialValues = {
  is_primary: false,
  address_line_1: '',
  address_line_2: '',
  city: '',
  state: '',
  state_id: '',
  district: '',
  district_id: '',
  country: "India",
  pincode: '',
  gst: '',
  deleted_at: false
}

const shippingAddressInitialValues = {
  is_primary: false,
  address_line_1: '',
  address_line_2: '',
  city: '',
  state: '',
  state_id: '',
  district: '',
  district_id: '',
  country: "India",
  pincode: '',
  store_keeper_name: '',
  store_keeper_number: '',
  deleted_at: false
}

const initialValues = {
  firm_name: '',
  business_contact_name: '',
  type: 2,
  firm_persons: [
    {
      name: "",
      email: "",
      whatsapp_number: "",
      notify_firm_person: 1,
      status: 1,
      verified: 1,
      is_primary: true,
      deleted_at: false,
    }
  ],
  latest_installed_capacity: '',
  sector: '',
  category: 1,
  GST_file_name: '',
  billing_address: [{
    is_primary: true,
    address_line_1: '',
    address_line_2: '',
    city: '',
    state: '',
    state_id: '',
    district: '',
    district_id: '',
    country: "India",
    pincode: '',
    gst: '',
    deleted_at: false
  }],
  shipping_address: [{
    is_primary: true,
    address_line_1: '',
    address_line_2: '',
    city: '',
    state: '',
    state_id: '',
    district: '',
    district_id: '',
    country: "India",
    pincode: '',
    store_keeper_name: '',
    store_keeper_number: '',
    deleted_at: false
  }],
  epr: '',
  sup: '',
  status: 1,
  verified: 1,
  remarks: '',
  buyerSellerInterest: [],
  created_by: ''
}

const AddEditBuyer = (props: any) => {
  const { config } = useLayout()
  const navigate = useNavigate()

  const params = useParams()
  const id: string = params.id ? params.id : ''

  const [loadingList, setLoadingList] = useState(false)
  const [loadingGetData, setLoadingGetData] = useState(false)
  const [loading, setLoading] = useState(false)
  const [lstGradeGroup, setLstGradeGroup] = useState([])
  const [existsNumber, setexistsnumber] = useState([])
  const [lstSelectedGradGroup, setLstSelectedGradGroup] = useState([])
  const [buyerSellerInterest, setBuyerSellerInterest] = useState([])
  const [buyerEditHistory, setBuyerEditHistory] = useState([])

  const [showSellerCertificateModal, setShowSellerCertificateModal] = useState<boolean>(false)
  const [lstGradeGroupWiswProduct, setLstGradeGroupWiswProduct] = useState([])
  const [allStateList, setAllStateList] = useState<any>([])
  const [allLocationList, setAllLocationList] = useState<any>([])
  const [sessionMessage, setSessionMessage] = useState({
    type: '',
    message: '',
    show: false,
  })

  useEffect(() => {
    getFirmList()
  }, [])

  useEffect(() => {
    getGradeList()
  }, [lstSelectedGradGroup])

  useEffect(() => {
    if (sessionMessage.show) {
      setTimeout(() => {
        setSessionMessage({ type: '', message: '', show: false })
      }, 5000)
    }
  }, [sessionMessage.show])

  const getGradeList = async () => {
    let query = lstSelectedGradGroup.join('&grade_group_id=')
    let lstTmp = await getGradeGroupWiseProducts('grade_group_id=' + query)
    let finalList = lstTmp.responseData.map((obj: any) => {
      return { value: obj.id, label: obj.name, grade_group_id: obj?.grade_group_id }
    })
    finalList.unshift({ "label": "Select Preferred Grade", value: -1 })
    setLstGradeGroupWiswProduct(finalList)
  }

  const getAllState = async () => {
    try {
      let allState: any = await getAllStateList()
      let finalData = allState.responseData.map((obj: any) => {
        return { value: obj.id, label: obj.name }
      })
      setAllStateList(finalData)
    }
    catch (err: any) {
      console.log(err)
    }
  }

  const getAllLocation = async () => {
    try {
      let allLocation: any = await getDistrictLocationASC()
      let finalData = allLocation.data.map((obj: any) => {
        return { value: obj.id, label: obj.name, state_id: obj.state_id }
      })
      setAllLocationList(finalData)
    }
    catch (err: any) {
      console.log(err)
    }
  }

  useEffect(() => {
    getAllState()
    getAllLocation()
    if (id) {
      getByID(id)
      getEditHistory(id)
    }
  }, [id])

  const getEditHistory = async (id: any) => {
    try {
      let { responseData, responseMessage, responseStatus } = await getBuyerSellerEditHistoryList(id)
      if (responseStatus == 200) {
        setBuyerEditHistory(responseData)
      }
      else {
        setSessionMessage({ type: 'danger', message: responseMessage, show: true })
      }
    }
    catch (err: any) {
      setSessionMessage({ type: 'danger', message: err.message, show: true })
    }
  }


  // Edit
  const getByID = async (id: string) => {
    try {
      setLoadingGetData(true)
      let data: any = await getSingleProductByID(id)
      console.log("data 377 = ", data);

      if (data?.responseData) {
        if (data?.responseData?.firm_name) {
          formik.setFieldValue('firm_name', data.responseData.firm_name)
        }
        if (data?.responseData?.business_contact_name) {
          formik.setFieldValue('business_contact_name', data.responseData.business_contact_name)
        }
        if (data?.responseData?.type) {
          formik.setFieldValue('type', data.responseData.type)
        }

        if (data?.responseData?.latest_installed_capacity) {
          formik.setFieldValue(
            'latest_installed_capacity',
            data.responseData.latest_installed_capacity
          )
        }
        if (data?.responseData?.latest_installed_capacity) {
          formik.setFieldValue(
            'latest_installed_capacity',
            data.responseData.latest_installed_capacity
          )
        }
        if (data?.responseData?.sector) {
          formik.setFieldValue('sector', data.responseData.sector)
        }
        if (data?.responseData?.GST_file_name) {
          formik.setFieldValue('GST_file_name', data.responseData.GST_file_name)
        }
        if (data?.responseData?.firmPersonInfo) {
          if (data.responseData.firmPersonInfo.length > 0) {
            let firm_name_list = data.responseData.firmPersonInfo.map((x: any) => {
              console.log("x 411 = ", x);

              return {
                id: x?.id ?? "",
                name: x?.name ?? "",
                email: x?.email ?? "",
                whatsapp_number: x?.whatsapp_number ?? "",
                notify_firm_person: x?.notify_firm_person ?? 0,
                sequence:x?.sequence,
                status: x?.status ?? "",
                verified: x?.verified ?? "",
                is_primary: x?.is_primary ?? false,
                deleted_at: false
              }
            })
            firm_name_list = firm_name_list.sort((a: any, b: any) => a.sequence - b.sequence)
            formik.setFieldValue('firm_persons', firm_name_list)
          }
        }
        if (data?.responseData?.buyerSellerPrimaryBillingInfo) {
          if (data.responseData.buyerSellerPrimaryBillingInfo.length > 0) {
            let billing_address_list = data.responseData.buyerSellerPrimaryBillingInfo.map((x: any) => {
              return {
                id: x?.id ?? "",
                is_primary: x?.is_primary ?? false,
                address_line_1: x?.address_line_1 ?? "",
                address_line_2: x?.address_line_2 ?? "",
                city: x?.city ?? "",
                state: x?.addressLocationInfo?.stateInfo?.name ?? "",
                state_id: x?.state_id ?? "",
                district: x?.addressLocationInfo?.name ?? "",
                district_id: x?.district_id ?? "",
                country: x?.country ?? "",
                pincode: x?.pincode ?? "",
                gst: x.gst ?? "",
                deleted_at: false
              }
            })
            billing_address_list = billing_address_list.sort((a: any, b: any) => b.is_primary - a.is_primary)
            formik.setFieldValue('billing_address', billing_address_list)
          }
        }
        if (data?.responseData?.buyerSellerPrimaryShippingInfo) {
          if (data.responseData.buyerSellerPrimaryShippingInfo.length > 0) {
            let shipping_address_list = data.responseData.buyerSellerPrimaryShippingInfo.map((x: any) => {
              return {
                id: x?.id ?? "",
                is_primary: x?.is_primary ?? false,
                address_line_1: x?.address_line_1 ?? "",
                address_line_2: x?.address_line_2 ?? "",
                city: x?.city ?? "",
                state: x?.addressLocationInfo?.stateInfo?.name ?? "",
                state_id: x?.state_id ?? "",
                district: x?.addressLocationInfo?.name ?? "",
                district_id: x.district_id ?? "",
                country: x?.country ?? "",
                pincode: x?.pincode ?? "",
                store_keeper_name: x?.store_keeper_name ?? "",
                store_keeper_number: x?.store_keeper_number ?? "",
                deleted_at: false
              }
            })
            shipping_address_list = shipping_address_list.sort((a: any, b: any) => b.is_primary - a.is_primary)
            formik.setFieldValue('shipping_address', shipping_address_list)
          }
        }
        if (data?.responseData?.epr) {
          formik.setFieldValue('epr', data.responseData.epr ?? "")
        }
        if (data?.responseData?.sup) {
          formik.setFieldValue('sup', data.responseData.sup ?? "")
        }
        if (data?.responseData?.status == true || data.responseData.status == false) {
          formik.setFieldValue('status', data.responseData.status)
        }
        if (data?.responseData?.verified) {
          formik.setFieldValue('verified', data.responseData.verified)
        }
        if (data?.responseData?.remarks) {
          formik.setFieldValue('remarks', data.responseData.remarks)
        }
        if (data?.responseData?.buyerSellerInterest) {
          formik.setFieldValue('buyerSellerInterest', data.responseData.buyerSellerInterest)
        }

        let onLoadSelectedGroupInfo = data.responseData.buyerSellerInterestInfo.map(
          (obj: any) => obj.gradeGroupInfo.id
        )

        let listSelecteDefuat = data.responseData.buyerSellerInterestInfo.map(
          (obj: any, index: number) => {
            return {
              grade_group_id: obj.productGroupInfo?.grade_group_id,
              product_group_id: obj?.product_group_id,
              interest_order: index + 1,
            }
          }
        )

        setLstSelectedGradGroup(onLoadSelectedGroupInfo)
        setBuyerSellerInterest(listSelecteDefuat)
        formik.setFieldValue(
          'category',
          Number(data.responseData.category) ? data.responseData.category : 2
        )
        formik.validateForm()
        setLoadingGetData(false)
      }
    }
    catch (err) {
      console.log("err", err)
    }

  }

  // Firm
  const getFirmList = async () => {
    setLoadingList(true)
    let dataFirm: any = await getAllGradeGroup()

    let lstTmpFirm = dataFirm.responseData.map((obj: any) => {
      return { value: obj.id, label: obj.name }
    })
    setLstGradeGroup(lstTmpFirm)

    setLoadingList(false)
  }

  const is_valid = (obj: any) => {
    if (obj.address_line_1 == '') {
      return true
    } else if (obj.address_line_2 == '') {
      return true
    } else if (obj.country == '') {
      return true
    } else if (obj.state == '') {
      return true
    } else if (obj.district == '') {
      return true
    } else if (obj.city == '') {
      return true
    } else if (obj.pincode == '') {
      return true
    } else {
      return false
    }
  }

  const is_valid_buyerSellerInterest = () => {
    let lstTmp = buyerSellerInterest.filter((obj: any) => {
      if (obj.interest_order == 1) {
        return obj
      } else if (obj.interest_order == 2) {
        return obj
      } else if (obj.interest_order == 3) {
        return obj
      } else if (obj.interest_order == 4) {
        return obj
      } else if (obj.interest_order == 5) {
        return obj
      }
    })

    if (lstTmp.length == 5) {
      return true
    } else {
      return false
    }
  }

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      try {
        if (buyerSellerInterest.length == 0) {
          setSessionMessage({ type: 'danger', message: 'Grade Group is required', show: true });
          setLoading(false)
          return
        }

        if (
          formik?.values?.firm_persons?.filter(
            (person, index) =>
              formik?.values?.firm_persons?.some(
                (otherPerson, otherIndex) =>
                  otherIndex !== index &&
                  person.whatsapp_number === otherPerson.whatsapp_number &&
                  !person.deleted_at &&
                  !otherPerson.deleted_at
              )
          ).length > 1
        ) {
          setSessionMessage({ type: 'danger', message: 'Duplicate WhatsApp number.', show: true });
          setLoading(false)
          return
        }
        if (id) {
          const isUpdate: any = await updateBuyerNew(id, {
            ...values,
            buyerSellerInterest: buyerSellerInterest,
            firm_persons : values.firm_persons.map((x:any,i:any) => {
              return {...x,"sequence":i+1}
            }),
          })
          
          if (isUpdate.responseStatus == 200) {
            setSessionMessage({ type: 'success', message: isUpdate.responseMessage, show: true })
            setTimeout(() => {
              navigate('/buyer-management/buyers')
            }, 4000)
          } else if (isUpdate.responseStatus == 400 || isUpdate.responseStatus == 500) {
            setSessionMessage({ type: 'danger', message: isUpdate.responseMessage, show: true })
            // navigate('/buyer-management/buyers')
            if (isUpdate.responseMessage == "Whatsapp number already exists.") {
              setexistsnumber(isUpdate?.responseData ?? [])
            }
            setLoading(false)
          }
          else {
            setLoading(false)
          }
        } else {
          
          const dataNew: any = await createBuyerNew({
            ...values,
            buyerSellerInterest: buyerSellerInterest,
            firm_persons : values.firm_persons.map((x:any,i:any) => {
              return {...x,"sequence":i+1}
            }),
            type: 1
          })
          console.log(dataNew,"jaja")
          if (dataNew && dataNew.responseStatus == 200) {
            setSessionMessage({ type: 'success', message: dataNew.responseMessage, show: true })
            setTimeout(() => {
              navigate('/buyer-management/buyers')
            }, 4000)
          }
          else if ((dataNew && dataNew.responseStatus == 400) || (dataNew && dataNew.responseStatus == 500)) {
            setSessionMessage({ type: 'danger', message: dataNew.responseMessage, show: true })
            if (dataNew.responseMessage == "Whatsapp number already exists.") {
              setexistsnumber(dataNew?.responseData ?? [])
            }
            setLoading(false)
          }
          else {
            setLoading(false)
          }
        }
      } catch (error: any) {
        console.error(error)
        setSessionMessage({ type: 'danger', message: error?.message ?? "Something went wrong", show: true })
        // saveAuth(undefined)
        setStatus('The login details are incorrect')
        setLoading(false)
      }
    },
  })

  useEffect(() => {
    formik.validateForm()
  }, [])

  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
  })

  // EPR
  const selectedValueEpr = (value: string) => {
    let selected = eprList.filter((obj: any) => obj.value == value)
    if (selected.length) {
      return selected[0]
    } else {
      return []
    }
  }

  // SUP
  const selectedValueSup = (value: string) => {
    let selected = supList.filter((obj: any) => obj.value == value)
    if (selected.length) {
      return selected[0]
    } else {
      return []
    }
  }

  // uploadGSTFile
  const uploadGstFile = async (file: any) => {
    var formData = new FormData()
    formData.append(`buyerSeller`, file)
    let filePath: any = await uploadGSTFile(formData)
    console.log(filePath.newFilename, 'filePath')
    formik.setFieldValue('GST_file_name', filePath.newFilename)
  }

  const changeBuyerEditStatus = async (historyId: any, payload: any) => {
    try {
      let { responseData, responseStatus, responseMessage } = await changeBuyerEditHistory(historyId, payload)
      if (responseStatus == 200) {
        getEditHistory(id)
        setSessionMessage({ type: 'success', message: responseMessage, show: true })
      }
      else {
        setSessionMessage({ type: 'danger', message: responseMessage, show: true })
      }
    }
    catch (err: any) {
      setSessionMessage({ type: 'danger', message: err.message, show: true })
    }
  }

  useEffect(() => {
    try {
      let user = localStorage.getItem("objUser") as any
      if (JSON.parse(user) && !id) {
        user = JSON.parse(user)
        formik.setFieldValue('created_by', user.id)
      }
    }
    catch (err: any) {
      console.log(err)
    }
  }, [])

  // console.log("formikformik", formik)

  const showBuyerDetailsError = (i: any, name: any) => {
    try {
      if (formik?.touched?.firm_persons) {
        let error = formik?.errors ?? {} as any
        let firm_persons = error?.firm_persons ?? [] as any
        if (firm_persons.length > 0) {
          let messageObj = firm_persons[i as any] ?? {} as any
          if (messageObj) {
            let message = messageObj[name as any] ?? null
            return message
          }
          else {
            return null
          }
        }
        else {
          return null
        }
      }
      else {
        return null
      }
    }
    catch (err: any) {
      return null
    }
  }

  return (
    <>
      {loadingGetData || loadingList ?
        <div
          style={{
            height: '550px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Spinner animation='border' />
        </div> :
        <div>
          <>
            <form
              className='form w-100'
              onSubmit={formik.handleSubmit}
              noValidate
              id='kt_login_signin_form'
              autoComplete="off"
            >
              <Row className='mb-5'>
                <Col xl={4}>
                  <Form.Group className='mb-5'>
                    <Form.Label className='form-label fw-bold'> Firm Name </Form.Label>
                    <Form.Control
                      type='text'
                      className='form-control-solid'
                      placeholder='Firm Name'
                      value={formik.values.firm_name}
                      onChange={(e: any) => {
                        // console.log(e.target.value, 'eee')
                        formik.setFieldValue('firm_name', e.target.value)
                      }}
                    />

                  </Form.Group>
                </Col>
                {/* <Col xl={4}>
                  <Form.Group className='mb-5'>
                    <Form.Label className='form-label fw-bold'> Business Contact Name </Form.Label>
                    <Form.Control
                      type='text'
                      className='form-control-solid'
                      placeholder='Business Contact Name'
                      value={formik.values.business_contact_name}
                      onChange={(e: any) => {
                        formik.setFieldValue('business_contact_name', e.target.value)
                      }}
                    />
                  </Form.Group>
                </Col> */}
              </Row>
              <h4 className='mb-5'> Personal Details </h4>
              {formik.values.firm_persons.map((buyerItem: any, index: any) => {

                if (!buyerItem.deleted_at) {
                  return <Row className='mb-5'>
                    <Col xl={4}>
                    {/* <Col xl={3}> */}
                      <Form.Group className='mb-5'>
                        <Form.Label className='required form-label fw-bold'> Buyer Name </Form.Label>
                        <span className="text-primary"><b>{" "}{(buyerItem.is_primary == 1 || buyerItem.is_primary == true) ? "Primary" : ""}</b></span>
                        <Form.Control
                          type='text'
                          className='form-control-solid'
                          placeholder='Buyer Name'
                          value={buyerItem.name}
                          onChange={(e: any) => {
                            formik.setFieldValue(`firm_persons[${index}].name`, e.target.value.trimStart())
                          }}
                        />
                        {showBuyerDetailsError(index, 'name') ? (
                          <div className='fv-plugins-message-container'>
                            <span role='alert' className={'text-danger'}>
                              {showBuyerDetailsError(index, 'name')}
                            </span>
                          </div>
                        ) : ""}
                      </Form.Group>
                    </Col>
                    <Col xl={4}>
                      <Form.Group className='mb-5'>
                        <Form.Label className='required form-label fw-bold'> Whatsapp Number </Form.Label>
                        <Form.Control
                          type='text'
                          className='form-control-solid'
                          placeholder='Whatsapp number'
                          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                            const key = e.key;
                            const isNumeric = /^[0-9]$/.test(key);
                            const isSpecialKey =
                              key === 'Backspace' ||
                              key === 'Delete' ||
                              key === 'ArrowLeft' ||
                              key === 'ArrowRight' ||
                              key === 'ArrowUp' ||
                              key === 'ArrowDown' ||
                              (e.ctrlKey && key === 'c') ||
                              (e.ctrlKey && key === 'v') ||
                              (e.ctrlKey && key === 'x') ||
                              (e.ctrlKey && key === 'a');
                            if (!isNumeric && !isSpecialKey) {
                              e.preventDefault();
                            }
                          }}
                          value={buyerItem.whatsapp_number}
                          onChange={(e: any) => {
                            formik.setFieldValue(`firm_persons[${index}].whatsapp_number`, e.target.value)
                          }}
                        />
                        {showBuyerDetailsError(index, 'whatsapp_number') ? (
                          <div className='fv-plugins-message-container'>
                            <span role='alert' className={'text-danger'}>
                              {showBuyerDetailsError(index, 'whatsapp_number')}
                            </span>
                          </div>
                        ) :
                          formik?.values?.firm_persons?.filter((x) => !x.deleted_at)?.filter((x) => x.whatsapp_number == buyerItem.whatsapp_number)?.length > 1 ? (
                            <div className='fv-plugins-message-container'>
                              <span role='alert' className={'text-danger'}>
                                This number is duplicate.
                              </span>
                            </div>
                          ) : existsNumber.find((x: any) => x == buyerItem.whatsapp_number) ? (
                            <div className='fv-plugins-message-container'>
                              <span role='alert' className={'text-danger'}>
                                This number already exists.
                              </span>
                            </div>
                          ) : ""}
                      </Form.Group>
                    </Col>
                    {/* <Col xl={3}> */}
                    <Col xl={2}>
                      <Form.Group className='mb-5'>
                        <Form.Label className='form-label fw-bold'> Email Id </Form.Label>
                        <Form.Control
                          type='email'
                          className='form-control-solid'
                          placeholder='Email Id'
                          value={buyerItem.email}
                          onChange={(e: any) => {
                            formik.setFieldValue(`firm_persons[${index}].email`, e.target.value)
                          }}
                        />
                      </Form.Group>
                    </Col>
                   
                    <Col xl={1}>
                      <Form.Group className='mb-3 d-flex align-items-center'>
                        <Form.Label className=' form-label'> Notify </Form.Label>
                      </Form.Group>
                      <Col className='d-flex mt-3'>
                        <Form.Group className='mb-3 d-flex align-items-center me-5'>
                        <Form.Check
                          className='mb-3 d-flex align-items-center me-5'
                          id={'notify_firm_person'}
                          type='switch'
                          checked={buyerItem.notify_firm_person}
                          onChange={(e) => {
                            formik.setFieldValue(`firm_persons[${index}].notify_firm_person`, e.target.checked)
                          }}
                          name='notify_firm_person'
                        />
                        </Form.Group>
                      </Col>
                    </Col>
                    <Col xl={1}>
                      <div className='d-flex'>
                        <button
                          type='button'
                          onClick={() => {
                            let oldArray = formik.values.firm_persons
                            oldArray.splice(index + 1, 0, buyerDetailInitialValues);
                            formik.setFieldValue("firm_persons", oldArray)
                          }}
                          className='btn btn-sm btn-icon mt-8 btn-color-success'
                        >
                          <KTSVG
                            path='/media/icons/duotune/general/gen035.svg'
                            className='svg-icon-muted svg-icon-2hx me-2'
                          />
                        </button>
                        {formik.values.firm_persons.filter((x) => x.deleted_at !== true).length > 1
                          ?
                          <button
                            type='button'
                            onClick={() => {
                              if (id) {
                                if (buyerItem.id) {
                                  formik.setFieldValue(`firm_persons[${index}].deleted_at`, true)
                                }
                                else {
                                  let oldArray = formik.values.firm_persons
                                  oldArray.splice(index, 1)
                                  formik.setFieldValue("firm_persons", oldArray)
                                }
                              }
                              else {
                                let oldArray = formik.values.firm_persons
                                oldArray.splice(index, 1)
                                formik.setFieldValue("firm_persons", oldArray)
                              }
                            }}
                            className='btn btn-sm btn-icon mt-8 btn-color-danger'
                          >
                            <KTSVG
                              path='/media/icons/duotune/general/gen036.svg'
                              className='svg-icon-muted svg-icon-2hx'
                            />
                          </button>
                          : ""}
                      </div>
                    </Col>
                  </Row>
                }
              })}

              <Row className='mb-5'>
                <Col xl={4}>
                  <Form.Group className='mb-5'>
                    <Form.Label className='form-label fw-bold'>
                      {' '}
                      Latest Installed Capacity{' '}
                    </Form.Label>
                    <Form.Control
                      type='text'
                      className='form-control-solid'
                      placeholder='Latest Installed Capacity'
                      value={formik.values.latest_installed_capacity}
                      onChange={(e: any) => {
                        formik.setFieldValue('latest_installed_capacity', e.target.value)
                      }}
                    />
                  </Form.Group>
                </Col>

                <Col xl={4}>
                  <Form.Group className='mb-5'>
                    <Form.Label className='form-label fw-bold'> Sector </Form.Label>
                    <Form.Control
                      type='text'
                      className='form-control-solid'
                      placeholder='Sector'
                      value={formik.values.sector}
                      onChange={(e: any) => {
                        formik.setFieldValue('sector', e.target.value)
                      }}
                    />
                  </Form.Group>
                </Col>

                <Col xl={4}>
                  <Form.Group className='mb-3 d-flex align-items-center'>
                    <Form.Label className=' form-label'>Category</Form.Label>
                  </Form.Group>
                  <Col className='d-flex mt-3'>
                    <Form.Group className='mb-3 d-flex align-items-center me-5'>
                      <Form.Check
                        className='me-5 fs-5 border-radius-0 form-control-solid text-grey-800'
                        id={'domestic'}
                        label='Domestic'
                        defaultChecked
                        type='radio'
                        checked={formik.values.category == 2}
                        onChange={(e) => {
                          formik.setFieldValue('category', 2)
                        }}
                        name='category'
                      />

                      <Form.Check
                        className='me-5 fs-5 border-radius-0 form-control-solid text-grey-600'
                        id={'deemedexport'}
                        label='Deemed Export'
                        type='radio'
                        name='category'
                        checked={formik.values.category == 1}
                        onChange={(e) => {
                          formik.setFieldValue('category', 1)
                        }}
                      />
                      <Form.Check
                        className=' fs-5 border-radius-0 form-control-solid text-grey-800'
                        id={'both'}
                        label='Both'
                        type='radio'
                        name='category'
                        checked={formik.values.category == 3}
                        onChange={(e) => {
                          formik.setFieldValue('category', 3)
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Col>
              </Row>
              <Row className='mb-5'>
                <Col className='text-start'>
                  <Form.Label className='required form-label text'>
                    Upload GST Certificate{' '}
                  </Form.Label>
                  <input
                    type={'file'}
                    id='file-input'
                    accept='application/pdf, image/jpeg, image/jpg, image/png'
                    style={{ display: 'none' }}
                    // {...getInputProps()}
                    onChange={(e) => {
                      let formatType = e?.target?.files?.[0]?.["type"] ?? "" as any
                      let formatArray = ["application/pdf", "image/jpeg", "image/jpg", "image/png"]
                      if (formatArray.indexOf(formatType) == -1) {
                        setSessionMessage({ type: 'danger', message: "Only PDF, JPEG, JPG and PNG format is Allow", show: true })
                        return
                      }
                      else if (e.target.files) {
                        // console.log(e.target.files[0].name, 'e-=-=')
                        uploadGstFile(e.target.files[0])
                        formik.setFieldValue('GST_file_name', e.target.files[0].name)
                      }
                    }}
                  />
                  <label htmlFor='file-input'>
                    <div>
                      <div {...getRootProps({ className: 'dropzone py-12' })}>
                        <div className='dz-message needsclick'>
                          <span className='svg-icon svg-icon-3hx svg-icon-primary'>
                            <KTSVG
                              path='/media/icons/duotune/files/fil009.svg'
                              className='svg-icon-muted svg-icon-2hx'
                            />
                          </span>
                          <div className='ms-4'>
                            <h3 className='fs-5 fw-bold text-gray-900 pt-5'>
                              Drag/Drop files here or click here
                            </h3>
                          </div>
                        </div>
                      </div>
                    </div>
                  </label>
                  {formik.touched.GST_file_name && formik.errors.GST_file_name && (
                    <div className='fv-plugins-message-container'>
                      <span role='alert' className={'text-danger'}>
                        {formik.errors.GST_file_name}
                      </span>
                    </div>
                  )}
                </Col>
                <Col>
                  <Col className='text-gray-600 fw-bold fs-5 mt-7'>
                    <div className='d-flex align-items-center gap-2 gap-lg-3 position-relative'>
                      {config.app?.toolbar?.primaryButton && (
                        <Link
                          to='#'
                          onClick={() => {
                            // console.log(formik.errors.GST_file_name, 'formik.errors.GST_file_name')
                            // setShowSellerCertificateModal(true)
                            if (formik.values?.GST_file_name != '')
                              setShowSellerCertificateModal(true)
                          }}
                          className='btn btn-sm fw-bold badge-light-primary mt-2'
                          data-bs-toggle='modal'
                          data-bs-target='#kt_modal_create_app'
                        >
                          <img
                            alt=''
                            src={toAbsoluteUrl('/media/demos/download-pdf.png')}
                            className='border-white h-30px w-100px h-100px me-3 '
                          />
                          Click to View
                        </Link>
                      )}
                      <a
                        style={{
                          position: 'absolute',
                          top: '-36px',
                          left: '-12px',
                          background: '#ff00004d',
                          borderRadius: '50%',
                        }}
                        className='btn btn-sm btn-icon mt-8 btn-color-danger'
                        onClick={() => {
                          formik.setFieldValue('GST_file_name', '')
                        }}
                      >
                        <i className='fa-solid fa-trash-can fs-3 text-danger'></i>
                      </a>
                      <SellerGstCertificate
                        loadingFile={false}
                        show={showSellerCertificateModal}
                        handleClose={() => setShowSellerCertificateModal(false)}
                        fileName={formik.values.GST_file_name}
                      />
                    </div>
                    <Form.Label className='form-label'> {formik.values.GST_file_name}</Form.Label>
                  </Col>
                </Col>
                <Col md={4}></Col>

              </Row>

              <Row>
                <div className='menu menu-column ' data-kt-menu='true'>
                  <div
                    className='d-flex flex-column bgi-no-repeat rounded-top'
                    style={{
                      backgroundImage: `url('{toAbsoluteUrl('/media/misc/menu-header-bg.jpg')}')`,
                    }}
                  >
                    <ul className='nav nav-line-tabs nav-line-tabs-2x nav-stretch fw-bold  px-0'>
                      <li className='nav-item'>
                        <a
                          className='nav-link text-dark opacity-75 fs-4 px-5 opacity-state-100 pb-4  active'
                          data-bs-toggle='tab'
                          href='#kt_topbar_notifications_1'
                        >
                          Grade Group details
                        </a>
                      </li>

                      <li className='nav-item'>
                        <a
                          className='nav-link text-dark opacity-75 fs-4 opacity-state-100 pb-4'
                          data-bs-toggle='tab'
                          href='#kt_topbar_notifications_2'
                        >
                          Address
                        </a>
                      </li>
                    </ul>
                  </div>

                  <div className='tab-content mb-10'>
                    <GradeGroupdetails
                      buyerSellerInterest={buyerSellerInterest}
                      setBuyerSellerInterest={setBuyerSellerInterest}
                      lstGradeGroup={lstGradeGroup}
                      lstSelectedGradGroup={lstSelectedGradGroup}
                      setLstSelectedGradGroup={setLstSelectedGradGroup}
                      lstGradeGroupWiswProduct={lstGradeGroupWiswProduct}
                      formik={formik}
                    />
                    <AddressCom
                      id={id}
                      formik={formik}
                      allStateList={allStateList}
                      allLocationList={allLocationList}
                    />
                  </div>
                  <Row className='mb-10'>
                    <Col lx={4}>
                      <Form.Group>
                        <Form.Label className='required fw-bold form-label'> EPR </Form.Label>
                        <Select
                          options={eprList}
                          className='custom_select'
                          classNamePrefix='Select'
                          placeholder='Select EPR'
                          value={selectedValueEpr(formik.values.epr)}
                          onChange={(e: any) => {
                            formik.setFieldValue('epr', e.value)
                          }}
                        />
                        {formik.touched.epr && formik.errors.epr && (
                          <div className='fv-plugins-message-container mt-5'>
                            <span role='alert' className={'text-danger'}>
                              {formik.errors.epr}
                            </span>
                          </div>
                        )}
                      </Form.Group>
                    </Col>
                    <Col lx={4}>
                      <Form.Group>
                        <Form.Label className='required fw-bold form-label'> SUP </Form.Label>
                        <Select
                          options={supList}
                          className='custom_select'
                          classNamePrefix='Select'
                          placeholder='Select SUP'
                          value={selectedValueSup(formik.values.sup)}
                          onChange={(e: any) => {
                            formik.setFieldValue('sup', e.value)
                          }}
                        />
                        {formik.touched.sup && formik.errors.sup && (
                          <div className='fv-plugins-message-container mt-5'>
                            <span role='alert' className={'text-danger'}>
                              {formik.errors.sup}
                            </span>
                          </div>
                        )}
                      </Form.Group>
                    </Col>
                    <Col lx={4}></Col>
                  </Row>

                  <Row className='mb-5'>
                    <Col xl={4}>
                      <Form.Group className='mb-3'>
                        <Form.Label className='form-label'>Status</Form.Label>
                        <Col className='d-flex  flex-wrap'>
                          <Form.Check
                            className='me-8 py-4 fs-5'
                            type='radio'
                            id={'active'}
                            checked={formik.values.status == 1 as any}
                            onChange={(e) => {
                              formik.setFieldValue('status', 1)
                            }}
                            label='Active'
                            name='radio3'
                          />
                          <Form.Check
                            type='radio'
                            className='py-4 fs-4'
                            label={'Inactive'}
                            id={'inactive'}
                            checked={formik.values.status == 0 as any}
                            onChange={(e) => {
                              formik.setFieldValue('status', 0)
                            }}
                            name='radio3'
                          />
                        </Col>
                      </Form.Group>
                    </Col>

                    <Col xl={4}>
                      <Form.Group className='mb-3'>
                        <Form.Label className='form-label'>Verification</Form.Label>
                        <Col className='d-flex  flex-wrap'>
                          <Form.Check
                            className='me-8 py-4 fs-5'
                            type='radio'
                            defaultChecked
                            id={'approved'}
                            checked={formik.values.verified == 1}
                            onChange={(e) => {
                              formik.setFieldValue('verified', 1)
                            }}
                            label='Approved'
                            name='radio2'
                          />
                          <Form.Check
                            type='radio'
                            className='me-8 py-4 fs-4'
                            checked={formik.values.verified == 2}
                            onChange={(e) => {
                              formik.setFieldValue('verified', 2)
                            }}
                            label={'Rejected'}
                            id={'rejected'}
                            name='radio2'
                          />
                          <Form.Check
                            type='radio'
                            className='me-8 py-4 fs-4'
                            checked={formik.values.verified == 3}
                            onChange={(e) => {
                              formik.setFieldValue('verified', 3)
                            }}
                            label={'Pending'}
                            id={'pending'}
                            name='radio2'
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row className='mb-5 mt-5'>
                    <Col lx={4} md={4}>
                      <Form.Group className='mb-3'>
                        <Form.Label className=' form-label'> Remarks </Form.Label>
                        <Form.Control
                          type='text'
                          as='textarea'
                          rows={5}
                          className='form-control-solid'
                          placeholder='Remarks'
                          value={formik.values.remarks}
                          onChange={(e: any) => {
                            formik.setFieldValue('remarks', e.target.value)
                          }}
                        />
                        {/* <Form.Control
                              type='text'
                              as='textarea'
                              rows={5}
                              className='form-control-solid border-0 '
                              placeholder=''
                            /> */}
                        <Form.Label className='mt-1 d-block text-end form-label'>
                          {' '}
                          Maximum 50 words
                        </Form.Label>
                      </Form.Group>
                    </Col>
                  </Row>
                </div>
              </Row>
              <Row className='mb-5'>
                <Col className='d-flex justify-content-end'>
                  <Button type='submit' value='' className='btn btn-sm btn-primary fs-5  me-5'
                    disabled={loading} onClick={() => {
                      if (formik?.errors?.billing_address || formik?.errors?.shipping_address) {
                        setSessionMessage({ type: 'danger', message: "Address field missing", show: true })
                      }
                    }}>

                    {loading ? "Please Wait..." : id ? 'Edit' : 'Add'}
                  </Button>
                  <Button
                    type='button'
                    value=''
                    onClick={() => {
                      navigate('/buyer-management/buyers')
                    }}
                    className='btn btn-sm fs-5 btn-light-primary'
                  >
                    Cancel{' '}
                  </Button>
                </Col>
              </Row>
            </form>
          </>

          {(id && id != "") ?
            <KTCardBody className='py-4'>
              <div className='table-responsive'>
                <table
                  id='kt_table_users'
                  className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                >
                  <thead>
                    <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0 bg-light'>
                      <th>Firm Person</th>
                      <th>Date</th>
                      <th>Whatsapp</th>
                      <th>Email</th>
                      <th>GST</th>
                      <th>ACTIONS</th>
                    </tr>
                  </thead>
                  <tbody
                    className='text-gray-600 fw-bold'
                  >
                    {buyerEditHistory.length > 0 ? (
                      buyerEditHistory.map((row: any, i: any) => {
                        return (
                          <tr>
                            <td>{checkDisplayValue(row?.firmPersonInfo?.name)}</td>
                            <td>{checkDisplayValue(row?.created_at) !== "-" ? moment(row?.created_at).format('LLL') : "-"}</td>
                            <td>{checkDisplayValue(row?.new_cell_number)}</td>
                            <td>{checkDisplayValue(row?.new_email)}</td>
                            <td>{
                              (row?.new_gst_file_name && row?.new_gst_file_name !== "") ?
                                <a href={downloadBuyerEditHistoryFile(row?.new_gst_file_name)} target="_blank" download>Download</a>
                                : "No File Found"
                            }</td>
                            <td>
                              <div
                                className='d-flex justify-content-start menu-gray-600 menu-state-bg-light-primary fw-bold fs-7'
                              >
                                <div className='menu-item px-1'>
                                  <OverlayTrigger
                                    placement='top'
                                    overlay={<Tooltip className='py-2 px-2 fs-2 '>Approve</Tooltip>}
                                  >
                                    <a
                                      className='menu-link px-1'
                                      onClick={() => changeBuyerEditStatus(row.id, { status: "1" })}
                                    >
                                      <i className="fa-regular fa-circle-check text-primary fs-1"></i>
                                    </a>
                                  </OverlayTrigger>
                                </div>
                                <div className='menu-item px-1'>
                                  <OverlayTrigger
                                    placement='top'
                                    overlay={<Tooltip className='py-2 px-2 fs-2 '>Reject</Tooltip>}
                                  >
                                    <a
                                      className='menu-link px-1'
                                      onClick={() => changeBuyerEditStatus(row.id, { status: "3" })}
                                    >
                                      <i className="fa-regular fa-circle-xmark text-danger fs-1"></i>
                                    </a>
                                  </OverlayTrigger>
                                </div>
                              </div>
                            </td>
                          </tr>
                        )
                      })
                    ) : (
                      <tr>
                        <td colSpan={10}>
                          <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                            No matching records found
                          </div>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </KTCardBody> : ""}
        </div>}

      <ToastContainer className='p-3 position-fixed' position={'top-center'}>
        <Toast
          show={sessionMessage.show}
          onClose={() => {
            setSessionMessage({
              type: '',
              message: '',
              show: false,
            })
          }}
          bg={sessionMessage.type}
        >
          {/* <Toast.Header>Error</Toast.Header> */}
          <Toast.Body className='text-white'>{sessionMessage.message}</Toast.Body>
        </Toast>
      </ToastContainer>
    </>
  )
}

const GradeGroupdetails = (props: any) => {
  const {
    setBuyerSellerInterest,
    buyerSellerInterest,
    lstGradeGroupWiswProduct,
    formik,
    lstGradeGroup,
    lstSelectedGradGroup,
    setLstSelectedGradGroup,
  } = props

  const onPreferredGradeChange = (e: any, groupNo: any) => {

    let buyerList = JSON.parse(JSON.stringify(buyerSellerInterest))
    let prevValue = buyerList.find((x: any) => x.interest_order == groupNo)
    if (prevValue) {
      let newObj = {
        grade_group_id: e.grade_group_id,
        product_group_id: e.value,
        interest_order: groupNo,
      }
      let prevIndex = buyerList.findIndex((x: any) => x.interest_order == groupNo)
      buyerList[prevIndex] = newObj
      setBuyerSellerInterest(buyerList)
    }
    else {
      let newObj = {
        grade_group_id: e.grade_group_id,
        product_group_id: e.value,
        interest_order: groupNo,
      }
      buyerList.push(newObj)
      setBuyerSellerInterest(buyerList)
    }
  }

  const removeSelectGrade = (interest_order: any) => {
    let newBuyerList = buyerSellerInterest.filter((x: any) => x.interest_order != interest_order)
    let buyerListSort = newBuyerList.sort((a: any, b: any) => a.interest_order - b.interest_order)
    setBuyerSellerInterest(buyerListSort.map((x: any, i: any) => {
      x.interest_order = i + 1
      return x
    }))
  }

  const seleteGroup = (interest_order: number) => {
  
    let productgroupid = buyerSellerInterest.find((obj: any) => {
      if (obj.interest_order == interest_order)
        return obj
    })
    if (productgroupid && productgroupid.product_group_id) {
      let lstTmp = lstGradeGroupWiswProduct.filter((obj: any) => {
        if (obj.value == productgroupid.product_group_id) {
          return obj
        }
      })
      if (lstTmp.length) {
        return lstTmp[0]
      } else {
        return []
      }
    } else {
      return []
    }

    //////////////////////////////////////////////////

    // let lstTmp = lstGradeGroupWiswProduct.filter((obj: any) => {
    //   if (buyerSellerInterest[groupNo])
    //     if (obj.value == buyerSellerInterest[groupNo].product_group_id) {
    //       return obj
    //     }
    // })
    // // console.log(lstTmp, 'lstTmp][')

    // if (lstTmp.length) {
    //   // console.log(lstTmp[0], 'callalal')

    //   return lstTmp[0]
    // } else {
    //   return []
    // }
  }

  // console.log(lstGradeGroupWiswProduct, 'lstGradeGroupWiswProduct')
  // console.log(buyerSellerInterest.filter((obj: any) => {
  //                             if (obj.grade_group_id != obj.value)
  //                               return obj
  //                           }), 'removebuyerSellerInterest()')

  return (
    <div className='tab-pane show active fade' id='kt_topbar_notifications_1' role='tabpanel'>
      <Row className='mb-3 py-8'>
        <Col>
          <Form.Group className='mb-3 mt-2 d-flex align-items-center'>
            <Form.Label className='required form-label'>
              Grade Group
            </Form.Label>
          </Form.Group>

          <Row className='d-flex flex-wrap mt-3'>
            {lstGradeGroup.length
              ? lstGradeGroup.map((obj: any) => {
                return (
                  <Col xl={2}>
                    <Form.Check
                      className='me-8 mb-6 fs-5 border-radius-0 form-control-solid text-dark'
                      id={obj.value}
                      label={obj.label}
                      checked={lstSelectedGradGroup.includes(obj.value)}
                      onChange={() => {
                        // console.log('call')
                        let isExist = lstSelectedGradGroup.includes(obj.value)
                        // console.log(isExist, 'isExist')

                        if (isExist) {
                          let lstTmp = lstSelectedGradGroup.filter((objSub: any) => {
                            // console.log(objSub.value, '!=', obj.value)
                            if (objSub != obj.value) {
                              return obj
                            }
                          })
                          setLstSelectedGradGroup(lstTmp)

                          //

                          let removeBuyerSellerInterest = buyerSellerInterest.filter((buyerSellerObj: any) => {
                            if (buyerSellerObj.grade_group_id != obj.value)
                              return obj
                          })
                          // console.log("removeBuyerSellerInterest",removeBuyerSellerInterest)
                          if (removeBuyerSellerInterest && removeBuyerSellerInterest.length) {
                            setBuyerSellerInterest(removeBuyerSellerInterest)
                          }

                        } else {
                          // if (lstSelectedGradGroup.length < 3) {
                          setLstSelectedGradGroup([...lstSelectedGradGroup, obj.value])
                          // }
                        }
                      }}
                    />
                  </Col>
                )
              })
              : ''}
          </Row>
        </Col>
      </Row>

      <Row className='mb-5'>
        <Form.Group className='mb-3 mt-2 d-flex align-items-center'>
          <Form.Label className='required form-label'>
            Please Choose upto five grades that you mostly need for manufacturing
          </Form.Label>
        </Form.Group>
        <Col xl={4}>
          <Form.Group>
            <Form.Label className='fw-bold form-label'> Preferred Grade#1 </Form.Label>
            <Select
              options={lstGradeGroupWiswProduct.filter((obj: any) => {
                if (
                  buyerSellerInterest[1]?.product_group_id != obj.value &&
                  buyerSellerInterest[2]?.product_group_id != obj.value &&
                  buyerSellerInterest[3]?.product_group_id != obj.value &&
                  buyerSellerInterest[4]?.product_group_id != obj.value &&
                  buyerSellerInterest[6]?.product_group_id != obj.value &&
                  buyerSellerInterest[5]?.product_group_id != obj.value
                ) {
                  return obj
                }
              })}
              className='custom_select'
              classNamePrefix='Select'
              placeholder='Preferred Grade#1'
              value={seleteGroup(1)}
              onChange={(e: any) => {
                if (e.value == -1) {
                  removeSelectGrade(1)
                } else {
                  onPreferredGradeChange(e, 1)
                }
              }}
            />
          </Form.Group>
        </Col>

        <Col xl={4}>
          <Form.Group>
            <Form.Label className='fw-bold form-label'> Preferred Grade#2 </Form.Label>
            <Select
              // options={lstGradeGroupWiswProduct}
              options={lstGradeGroupWiswProduct.filter((obj: any) => {
                if (
                  buyerSellerInterest[0]?.product_group_id != obj.value &&
                  buyerSellerInterest[2]?.product_group_id != obj.value &&
                  buyerSellerInterest[3]?.product_group_id != obj.value &&
                  buyerSellerInterest[4]?.product_group_id != obj.value &&
                  buyerSellerInterest[6]?.product_group_id != obj.value &&
                  buyerSellerInterest[5]?.product_group_id != obj.value
                ) {
                  return obj
                }
              })}
              className='custom_select'
              classNamePrefix='Select'
              placeholder='Preferred Grade#2'
              value={seleteGroup(2)}
              onChange={(e: any) => {
                if (e.value == -1) {
                  removeSelectGrade(2)
                } else {
                  onPreferredGradeChange(e, 2)
                }
              }}
            />
          </Form.Group>
        </Col>

        <Col xl={4}>
          <Form.Group>
            <Form.Label className='fw-bold form-label'> Preferred Grade#3 </Form.Label>
            <Select
              // options={lstGradeGroupWiswProduct}
              options={lstGradeGroupWiswProduct.filter((obj: any) => {
                if (
                  buyerSellerInterest[0]?.product_group_id != obj.value &&
                  buyerSellerInterest[1]?.product_group_id != obj.value &&
                  buyerSellerInterest[3]?.product_group_id != obj.value &&
                  buyerSellerInterest[4]?.product_group_id != obj.value &&
                  buyerSellerInterest[6]?.product_group_id != obj.value &&
                  buyerSellerInterest[5]?.product_group_id != obj.value
                ) {
                  return obj
                }
              })}
              className='custom_select'
              classNamePrefix='Select'
              placeholder='Preferred Grade#3'
              value={seleteGroup(3)}
              onChange={(e: any) => {
                if (e.value == -1) {
                  removeSelectGrade(3)
                } else {
                  onPreferredGradeChange(e, 3)
                }
              }}
            />
          </Form.Group>
        </Col>

        <Col xl={4}>
          <Form.Group>
            <Form.Label className='fw-bold form-label'> Preferred Grade#4 </Form.Label>
            <Select
              // options={lstGradeGroupWiswProduct}
              options={lstGradeGroupWiswProduct.filter((obj: any) => {
                if (
                  buyerSellerInterest[0]?.product_group_id != obj.value &&
                  buyerSellerInterest[1]?.product_group_id != obj.value &&
                  buyerSellerInterest[2]?.product_group_id != obj.value &&
                  buyerSellerInterest[4]?.product_group_id != obj.value &&
                  buyerSellerInterest[6]?.product_group_id != obj.value &&
                  buyerSellerInterest[5]?.product_group_id != obj.value
                ) {
                  return obj
                }
              })}
              className='custom_select'
              classNamePrefix='Select'
              placeholder='Preferred Grade#4'
              value={seleteGroup(4)}
              onChange={(e: any) => {
                if (e.value == -1) {
                  removeSelectGrade(4)
                } else {
                  onPreferredGradeChange(e, 4)
                }
              }}
            />
          </Form.Group>
        </Col>

        <Col xl={4}>
          <Form.Group>
            <Form.Label className='fw-bold form-label'> Preferred Grade#5 </Form.Label>
            <Select
              // options={lstGradeGroupWiswProduct}
              options={lstGradeGroupWiswProduct.filter((obj: any) => {
                if (
                  buyerSellerInterest[0]?.product_group_id != obj.value &&
                  buyerSellerInterest[1]?.product_group_id != obj.value &&
                  buyerSellerInterest[2]?.product_group_id != obj.value &&
                  buyerSellerInterest[3]?.product_group_id != obj.value &&
                  buyerSellerInterest[6]?.product_group_id != obj.value &&
                  buyerSellerInterest[5]?.product_group_id != obj.value
                ) {
                  return obj
                }
              })}
              className='custom_select'
              classNamePrefix='Select'
              placeholder='Preferred Grade#5'
              value={seleteGroup(5)}
              onChange={(e: any) => {
                if (e.value == -1) {
                  removeSelectGrade(5)
                } else {
                  onPreferredGradeChange(e, 5)
                }
              }}
            />
          </Form.Group>
        </Col>

        <Col xl={4}>
          <Form.Group>
            <Form.Label className='fw-bold form-label'> Preferred Grade#6 </Form.Label>
            <Select
              // options={lstGradeGroupWiswProduct}
              options={lstGradeGroupWiswProduct.filter((obj: any) => {
                if (
                  buyerSellerInterest[0]?.product_group_id != obj.value &&
                  buyerSellerInterest[1]?.product_group_id != obj.value &&
                  buyerSellerInterest[2]?.product_group_id != obj.value &&
                  buyerSellerInterest[3]?.product_group_id != obj.value &&
                  buyerSellerInterest[4]?.product_group_id != obj.value &&
                  buyerSellerInterest[6]?.product_group_id != obj.value
                ) {
                  return obj
                }
              })}
              className='custom_select'
              classNamePrefix='Select'
              placeholder='Preferred Grade#6'
              value={seleteGroup(6)}
              onChange={(e: any) => {
                if (e.value == -1) {
                  removeSelectGrade(6)
                } else {
                  onPreferredGradeChange(e, 6)
                }
              }}
            />
          </Form.Group>
        </Col>
        <Col xl={4}>
          <Form.Group>
            <Form.Label className='fw-bold form-label'> Preferred Grade#7 </Form.Label>
            <Select
              // options={lstGradeGroupWiswProduct}
              options={lstGradeGroupWiswProduct.filter((obj: any) => {
                if (
                  buyerSellerInterest[0]?.product_group_id != obj.value &&
                  buyerSellerInterest[1]?.product_group_id != obj.value &&
                  buyerSellerInterest[2]?.product_group_id != obj.value &&
                  buyerSellerInterest[3]?.product_group_id != obj.value &&
                  buyerSellerInterest[4]?.product_group_id != obj.value &&
                  buyerSellerInterest[5]?.product_group_id != obj.value
                ) {
                  return obj
                }
              })}
              className='custom_select'
              classNamePrefix='Select'
              placeholder='Preferred Grade#7'
              value={seleteGroup(7)}
              onChange={(e: any) => {
                if (e.value == -1) {
                  removeSelectGrade(7)
                } else {
                  onPreferredGradeChange(e, 7)
                }
              }}
            />
          </Form.Group>
        </Col>
      </Row>
    </div>
  )
}
const AddressCom = (props: any) => {
  const {
    id,
    formik,
    allStateList,
    allLocationList
  } = props

  const showBillingAddressError = (i: any, name: any) => {
    try {
      if (formik?.touched?.billing_address) {
        let error = formik?.errors ?? {} as any
        let billing_address = error?.billing_address ?? [] as any
        if (billing_address.length > 0) {
          let messageObj = billing_address[i as any] ?? {} as any
          if (messageObj) {
            let message = messageObj[name as any] ?? null
            return message
          }
          else {
            return null
          }
        }
        else {
          return null
        }
      }
      else {
        return null
      }
    }
    catch (err: any) {
      return null
    }
  }

  const showShippingAddressError = (i: any, name: any) => {
    try {
      if (formik?.touched?.shipping_address) {
        let error = formik?.errors ?? {} as any
        let shipping_address = error?.shipping_address ?? [] as any
        if (shipping_address.length > 0) {
          let messageObj = shipping_address[i as any] ?? {} as any
          if (messageObj) {
            let message = messageObj[name as any] ?? null
            return message
          }
          else {
            return null
          }
        }
        else {
          return null
        }
      }
      else {
        return null
      }
    }
    catch (err: any) {
      return null
    }
  }

  const copyBillingAddress = () => {
    let copyAddress = formik.values.billing_address.find((x:any)=>(x?.is_primary == 1 || x?.is_primary == true) && !x?.deleted_at)
    if(copyAddress){
      let copyBillingAddressForShippingAddress = formik.values.shipping_address.map((item: any, i: any) => {
        if ((item?.is_primary == 1 || item?.is_primary == true) && !item?.deleted_at) {
          return {
            is_primary: true,
            address_line_1: copyAddress?.address_line_1 ?? "",
            address_line_2: copyAddress?.address_line_2 ?? "",
            city: copyAddress?.city ?? "",
            state: copyAddress?.state ?? "",
            state_id: copyAddress?.state_id ?? "",
            district: copyAddress?.district ?? "",
            district_id: copyAddress?.district_id ?? "",
            country: "India",
            pincode: copyAddress?.pincode ?? "",
            store_keeper_name: item?.store_keeper_name ?? "",
            store_keeper_number: item?.store_keeper_number ?? "",
            deleted_at: false
          } 
        } else {
          return item
        }
      });
      formik.setFieldValue('shipping_address', copyBillingAddressForShippingAddress);
    }
  }

  return (
    <div className='tab-pane fade' id='kt_topbar_notifications_2' role='tabpanel'>
      <Col className=' flex-column flex-lg-row-auto py-8'>
        <Row className='d-flex justify-content-between'>
          <Col xl={12}>
            <Col className='px-2'>
              <h2 className=''> Billing Address </h2>
              {formik.values.billing_address.map((addressItem: any, index: number) => {
                if (!addressItem.deleted_at) {
                  return <Row>
                    {index == 0 ? <hr className='mt-5' /> : ""}
                    <Col md={6}>
                      <Col>
                        {/* <Form.Group className='mb-3'> */}
                        <Form.Label className='required form-label fw-bold'> Address Line 1</Form.Label>
                        <span className="text-primary"><b>{" "}{addressItem.is_primary == 1 || addressItem.is_primary == true ? "Primary Address" : ""}</b></span>
                        <Form.Control
                          type='text'
                          className='form-control-solid'
                          placeholder='Address Line 1'
                          value={addressItem.address_line_1}
                          onChange={(e: any) => {
                            formik.setFieldValue(`billing_address[${index}].address_line_1`, e.target.value.trimStart())
                          }}
                        />
                      </Col>
                      {showBillingAddressError(index, 'address_line_1') ? (
                        <div className='fv-plugins-message-container'>
                          <span role='alert' className={'text-danger'}>
                            {showBillingAddressError(index, 'address_line_1')}
                          </span>
                        </div>
                      ) : ""}
                      <Col>
                        <Form.Label className='required form-label fw-bold'> Address Line 2 </Form.Label>
                        <Form.Control
                          type='text'
                          className='form-control-solid'
                          placeholder='Address Line 2'
                          value={addressItem?.address_line_2}
                          onChange={(e: any) => {
                            formik.setFieldValue(`billing_address[${index}].address_line_2`, e.target.value.trimStart())
                          }}
                        />
                        {showBillingAddressError(index, 'address_line_2') ? (
                          <div className='fv-plugins-message-container'>
                            <span role='alert' className={'text-danger'}>
                              {showBillingAddressError(index, 'address_line_2')}
                            </span>
                          </div>
                        ) : ""}
                      </Col>
                      <Col>
                        <Form.Label className='required form-label fw-bold'> GST Number </Form.Label>
                        <Form.Control
                          type='text'
                          className='form-control-solid'
                          placeholder='GST Number'
                          value={addressItem?.gst}
                          onChange={(e: any) => {
                            formik.setFieldValue(`billing_address[${index}].gst`, e.target.value.trimStart())
                          }}
                        />
                        {showBillingAddressError(index, 'gst') ? (
                          <div className='fv-plugins-message-container'>
                            <span role='alert' className={'text-danger'}>
                              {showBillingAddressError(index, 'gst')}
                            </span>
                          </div>
                        ) : ""}
                      </Col>
                    </Col>
                    <Col md={5}>
                      <Row className=''>
                        <Col xl={6}>
                          <Form.Label className='required fw-bold fs-6 mb-2 form-label'>State</Form.Label>
                          <Select
                            options={allStateList}
                            className='custom_select'
                            classNamePrefix='Select'
                            placeholder='Select State'
                            value={addressItem.state_id ? allStateList.filter((x: any) => x.value == addressItem.state_id) : []}
                            onChange={(e: any) => {
                              formik.setFieldValue(`billing_address[${index}].state`, e.value)
                              formik.setFieldValue(`billing_address[${index}].state_id`, e.value)
                              formik.setFieldValue(`billing_address[${index}].district`, '')
                              formik.setFieldValue(`billing_address[${index}].district_id`, '')

                            }}
                          />
                          {showBillingAddressError(index, 'state_id') ? (
                            <div className='fv-plugins-message-container'>
                              <span role='alert' className={'text-danger'}>
                                {showBillingAddressError(index, 'state_id')}
                              </span>
                            </div>
                          ) : ""}
                        </Col>
                        <Col xl={6}>
                          <Form.Label className='required form-label'> District </Form.Label>
                          <Select
                            options={allLocationList.filter((x: any) => x.state_id == addressItem?.state_id)}
                            className='custom_select'
                            classNamePrefix='Select'
                            placeholder='Select District'
                            value={addressItem?.district_id ? allLocationList.filter((x: any) => x.value == addressItem?.district_id) : []}
                            onChange={(e: any) => {
                              formik.setFieldValue(`billing_address[${index}].district`, e.value)
                              formik.setFieldValue(`billing_address[${index}].district_id`, e.value)
                            }}
                          />
                          {showBillingAddressError(index, 'district_id') ? (
                            <div className='fv-plugins-message-container'>
                              <span role='alert' className={'text-danger'}>
                                {showBillingAddressError(index, 'district_id')}
                              </span>
                            </div>
                          ) : ""}
                        </Col>
                      </Row>
                      <Row className=''>
                        <Col xl={6}>
                          <Form.Label className='required form-label fw-bold'> City </Form.Label>
                          <Form.Control
                            type='text'
                            className='form-control-solid'
                            placeholder='City'
                            value={addressItem?.city}
                            onChange={(e: any) => {
                              formik.setFieldValue(`billing_address[${index}].city`, e.target.value.trimStart())
                            }}
                          />
                          {showBillingAddressError(index, 'city') ? (
                            <div className='fv-plugins-message-container'>
                              <span role='alert' className={'text-danger'}>
                                {showBillingAddressError(index, 'city')}
                              </span>
                            </div>
                          ) : ""}
                        </Col>
                        <Col xl={6}>
                          <Form.Label className='required form-label fw-bold'> Pincode </Form.Label>
                          <Form.Control
                            type='text'
                            className='form-control-solid'
                            placeholder='Pincode'
                            value={addressItem?.pincode}
                            onChange={(e: any) => {
                              formik.setFieldValue(`billing_address[${index}].pincode`, e.target.value.trimStart())
                            }}
                          />
                          {showBillingAddressError(index, 'pincode') ? (
                            <div className='fv-plugins-message-container'>
                              <span role='alert' className={'text-danger'}>
                                {showBillingAddressError(index, 'pincode')}
                              </span>
                            </div>
                          ) : ""}
                        </Col>
                        <Col xl={6} className='mt-12'>
                          <Form.Group className='mb-3 d-flex align-items-center me-5'>
                            <Form.Check
                              className='me-5 fs-5 border-radius-0 form-control-solid text-grey-800'
                              id={`prime_${index}`}
                              label='Primary'
                              type='radio'
                              checked={addressItem.is_primary}
                              onChange={() => {
                                const updatedAddresses = formik.values.billing_address.map((item: any, i: any) => {
                                  if (i === index) {
                                    return { ...item, is_primary: true };
                                  } else {
                                    return { ...item, is_primary: false };
                                  }
                                });
                                formik.setFieldValue('billing_address', updatedAddresses);
                              }}
                              name='primeA'
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={1}>
                      <div className='d-flex'>
                        <button
                          type='button'
                          onClick={() => {
                            let oldArray = formik.values.billing_address
                            oldArray.splice(index + 1, 0, billingAddressInitialValues);
                            formik.setFieldValue("billing_address", oldArray)
                          }}
                          className='btn btn-sm btn-icon mt-8 btn-color-success'
                        >
                          <KTSVG
                            path='/media/icons/duotune/general/gen035.svg'
                            className='svg-icon-muted svg-icon-2hx me-2'
                          />
                        </button>
                        {formik.values.billing_address.filter((x: any) => x.deleted_at !== true).length > 1
                          ?
                          <button
                            type='button'
                            onClick={() => {
                              if (id) {
                                if (addressItem.id) {
                                  formik.setFieldValue(`billing_address[${index}].deleted_at`, true)
                                }
                                else {
                                  let oldArray = formik.values.billing_address
                                  oldArray.splice(index, 1)
                                  formik.setFieldValue("billing_address", oldArray)
                                }
                              }
                              else {
                                let oldArray = formik.values.billing_address
                                oldArray.splice(index, 1)
                                formik.setFieldValue("billing_address", oldArray)
                              }
                            }}
                            className='btn btn-sm btn-icon mt-8 btn-color-danger'
                          >
                            <KTSVG
                              path='/media/icons/duotune/general/gen036.svg'
                              className='svg-icon-muted svg-icon-2hx'
                            />
                          </button>
                          : ""}
                      </div>
                    </Col>
                    <hr className='mt-5' />
                  </Row>
                }
              })}
              {/* </Form.Group> */}
            </Col>
          </Col>
        </Row>
      </Col>
      <Col className=' flex-column flex-lg-row-auto py-8'>
        <Row className='d-flex justify-content-between'>
          <Col xl={12}>
            <Col className='px-2'>
              <h2 className=''> Shipping Address </h2>
              {formik.values.shipping_address.map((addressItem: any, index: number) => {
                if (!addressItem.deleted_at) {
                  return <Row>
                    {index == 0 ? <hr className='mt-5' /> : ""}
                    <Col md={6}>
                      <Col>
                        <Form.Label className='form-label fw-bold'> Store Keeper Name </Form.Label>
                        <span className="text-primary"><b>{" "}{addressItem.is_primary == 1 || addressItem.is_primary == true ? "Primary Address" : ""}</b></span>
                        <Form.Control
                          type='text'
                          className='form-control-solid'
                          placeholder='Store Keeper Name'
                          value={addressItem?.store_keeper_name}
                          onChange={(e: any) => {
                            formik.setFieldValue(`shipping_address[${index}].store_keeper_name`, e.target.value.trimStart())
                          }}
                        />
                      </Col>
                      <Col>
                        {/* <Form.Group className='mb-3'> */}
                        <Form.Label className='required form-label fw-bold'> Address Line 1 </Form.Label>
                        <Form.Control
                          type='text'
                          className='form-control-solid'
                          placeholder='Address Line 1'
                          value={addressItem.address_line_1}
                          onChange={(e: any) => {
                            formik.setFieldValue(`shipping_address[${index}].address_line_1`, e.target.value.trimStart())
                          }}
                        />
                        {showShippingAddressError(index, 'address_line_1') ? (
                          <div className='fv-plugins-message-container'>
                            <span role='alert' className={'text-danger'}>
                              {showShippingAddressError(index, 'address_line_1')}
                            </span>
                          </div>
                        ) : ""}
                      </Col>
                      <Col>
                        <Form.Label className='required form-label fw-bold'> Address Line 2 </Form.Label>
                        <Form.Control
                          type='text'
                          className='form-control-solid'
                          placeholder='Address Line 2'
                          value={addressItem?.address_line_2}
                          onChange={(e: any) => {
                            formik.setFieldValue(`shipping_address[${index}].address_line_2`, e.target.value.trimStart())
                          }}
                        />
                        {showShippingAddressError(index, 'address_line_2') ? (
                          <div className='fv-plugins-message-container'>
                            <span role='alert' className={'text-danger'}>
                              {showShippingAddressError(index, 'address_line_2')}
                            </span>
                          </div>
                        ) : ""}
                      </Col>
                    </Col>

                    <Col md={5}>
                      <Row>
                        <Col>
                          <Form.Label className='form-label fw-bold'> Store Keeper Number</Form.Label>
                          <Form.Control
                            type='text'
                            className='form-control-solid'
                            placeholder='Store Keeper Number'
                            value={addressItem?.store_keeper_number}
                            onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                              const key = e.key;
                              const isNumeric = /^[0-9]$/.test(key);
                              const isSpecialKey =
                                key === 'Backspace' ||
                                key === 'Delete' ||
                                key === 'ArrowLeft' ||
                                key === 'ArrowRight' ||
                                key === 'ArrowUp' ||
                                key === 'ArrowDown';
                              if (!isNumeric && !isSpecialKey) {
                                e.preventDefault();
                              }
                            }}
                            onChange={(e: any) => {
                              formik.setFieldValue(`shipping_address[${index}].store_keeper_number`, e.target.value)
                            }}
                          />
                        </Col>
                      </Row>
                      <Row className=''>
                        <Col xl={6}>
                          <Form.Label className='required fw-bold fs-6 mb-2 form-label'>State</Form.Label>
                          <Select
                            options={allStateList}
                            className='custom_select'
                            classNamePrefix='Select'
                            placeholder='Select State'
                            value={addressItem.state_id ? allStateList.filter((x: any) => x.value == addressItem.state_id) : []}
                            onChange={(e: any) => {
                              formik.setFieldValue(`shipping_address[${index}].state`, e.value)
                              formik.setFieldValue(`shipping_address[${index}].state_id`, e.value)
                              formik.setFieldValue(`shipping_address[${index}].district`, '')
                              formik.setFieldValue(`shipping_address[${index}].district_id`, '')

                            }}
                          />
                          {showShippingAddressError(index, 'state_id') ? (
                            <div className='fv-plugins-message-container'>
                              <span role='alert' className={'text-danger'}>
                                {showShippingAddressError(index, 'state_id')}
                              </span>
                            </div>
                          ) : ""}
                        </Col>
                        <Col xl={6}>
                          <Form.Label className='required form-label'> District </Form.Label>
                          <Select
                            options={allLocationList.filter((x: any) => x.state_id == addressItem?.state_id)}
                            className='custom_select'
                            classNamePrefix='Select'
                            placeholder='Select District'
                            value={addressItem?.district_id ? allLocationList.filter((x: any) => x.value == addressItem?.district_id) : []}
                            onChange={(e: any) => {
                              formik.setFieldValue(`shipping_address[${index}].district`, e.value)
                              formik.setFieldValue(`shipping_address[${index}].district_id`, e.value)
                            }}
                          />
                          {showShippingAddressError(index, 'district_id') ? (
                            <div className='fv-plugins-message-container'>
                              <span role='alert' className={'text-danger'}>
                                {showShippingAddressError(index, 'district_id')}
                              </span>
                            </div>
                          ) : ""}
                        </Col>
                      </Row>
                      <Row className=''>
                        <Col xl={6}>
                          <Form.Label className='required form-label fw-bold'> City </Form.Label>
                          <Form.Control
                            type='text'
                            className='form-control-solid'
                            placeholder='City'
                            value={addressItem?.city}
                            onChange={(e: any) => {
                              formik.setFieldValue(`shipping_address[${index}].city`, e.target.value.trimStart())
                            }}
                          />
                          {showShippingAddressError(index, 'city') ? (
                            <div className='fv-plugins-message-container'>
                              <span role='alert' className={'text-danger'}>
                                {showShippingAddressError(index, 'city')}
                              </span>
                            </div>
                          ) : ""}
                        </Col>
                        <Col xl={6}>
                          <Form.Label className='required form-label fw-bold'> Pincode </Form.Label>
                          <Form.Control
                            type='text'
                            className='form-control-solid'
                            placeholder='Pincode'
                            value={addressItem?.pincode}
                            onChange={(e: any) => {
                              formik.setFieldValue(`shipping_address[${index}].pincode`, e.target.value.trimStart())
                            }}
                          />
                          {showShippingAddressError(index, 'pincode') ? (
                            <div className='fv-plugins-message-container'>
                              <span role='alert' className={'text-danger'}>
                                {showShippingAddressError(index, 'pincode')}
                              </span>
                            </div>
                          ) : ""}
                        </Col>
                      </Row>
                    </Col>
                    <Col md={1}>
                      <div className='d-flex'>
                        <button
                          type='button'
                          onClick={() => {
                            let oldArray = formik.values.shipping_address
                            oldArray.splice(index + 1, 0, shippingAddressInitialValues);
                            formik.setFieldValue("shipping_address", oldArray)
                          }}
                          className='btn btn-sm btn-icon mt-8 btn-color-success'
                        >
                          <KTSVG
                            path='/media/icons/duotune/general/gen035.svg'
                            className='svg-icon-muted svg-icon-2hx me-2'
                          />
                        </button>
                        {formik.values.shipping_address.filter((x: any) => x.deleted_at !== true).length > 1
                          ?
                          <button
                            type='button'
                            onClick={() => {
                              if (id) {
                                if (addressItem.id) {
                                  formik.setFieldValue(`shipping_address[${index}].deleted_at`, true)
                                }
                                else {
                                  let oldArray = formik.values.shipping_address
                                  oldArray.splice(index, 1)
                                  formik.setFieldValue("shipping_address", oldArray)
                                }
                              }
                              else {
                                let oldArray = formik.values.shipping_address
                                oldArray.splice(index, 1)
                                formik.setFieldValue("shipping_address", oldArray)
                              }
                            }}
                            className='btn btn-sm btn-icon mt-8 btn-color-danger'
                          >
                            <KTSVG
                              path='/media/icons/duotune/general/gen036.svg'
                              className='svg-icon-muted svg-icon-2hx'
                            />
                          </button>
                          : ""}
                      </div>
                    </Col>
                    <Col xl={6} className='mt-6'>

                      <Form.Group className='mb-3 d-flex align-items-center me-5'>
                        <Form.Check
                          className='me-5 fs-5 border-radius-0 form-control-solid text-grey-800'
                          id={`prime_${index}`}
                          label='Primary'
                          type='radio'
                          checked={addressItem.is_primary}
                          onChange={() => {
                            const updatedAddresses = formik.values.shipping_address.map((item: any, i: any) => {
                              if (i === index) {
                                return { ...item, is_primary: true };
                              } else {
                                return { ...item, is_primary: false };
                              }
                            });
                            formik.setFieldValue('shipping_address', updatedAddresses);
                          }}
                          name='prime'
                        />
                      </Form.Group>
                      {(addressItem.is_primary == 1 || addressItem.is_primary == true) ? 
                        <span className="text-primary" onClick={()=>copyBillingAddress()}>
                          <b>Copy from Billing Address</b>
                        </span> :
                        ""
                      }

                    </Col>
                    <hr className='mt-5' />
                  </Row>
                }
              })}
              {/* </Form.Group> */}
            </Col>
          </Col>
        </Row>
      </Col>
    </div>
  )
}

export default AddEditBuyer
