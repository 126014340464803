import { Dispatch, SetStateAction } from 'react'
import { Freight } from '../../../app/modules/freight-management/freights-list/core/_models'
import { Grade } from "../../../app/modules/grade-group-management/grades-list/core/_models";

export type ID = undefined | null | number

export type PaginationState = {
  page: number
  perPage?: 10 | 30 | 50 | 100
  items_per_page: 10 | 30 | 50 | 100
  links?: Array<{ label: string; active: boolean; url: string | null; page: number | null }>
}

export type SortState = {
  sort?: string
  order?: 'asc' | 'desc'
}

export type FilterState = {
  filter?: unknown
}

export type SearchState = {
  search?: string,
  searchMobileNumber?: string
}

export type Response<T> = {
  responseData: any
  data?: T
  payload?: {
    message?: string
    errors?: {
      [key: string]: Array<string>
    }
    pagination?: PaginationState
  }
}

export type QueryState = PaginationState & SortState & FilterState & SearchState

export type QueryRequestContextProps = {
  state: QueryState
  updateState: (updates: Partial<QueryState>) => void
}

export const initialQueryState: QueryState = {
  page: 1,
  perPage: 10,
  items_per_page: 10,
}

export const initialQueryRequest: QueryRequestContextProps = {
  state: initialQueryState,
  updateState: () => { },
}

export type QueryResponseContextProps<T> = {
  response?: Response<Array<T>> | undefined
  refetch: () => void
  isLoading: boolean
  query: string
}

export const initialQueryResponse = { refetch: () => { }, isLoading: false, query: '' }

export type ListViewContextProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  itemIdForUpdate?: ID
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>
  isAllSelected: boolean
  disabled: boolean
}

export const initialListView: ListViewContextProps = {
  selected: [],
  onSelect: () => { },
  onSelectAll: () => { },
  clearSelected: () => { },
  setItemIdForUpdate: () => { },
  isAllSelected: false,
  disabled: false,
}

export type ListViewContextFreightProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  itemIdForUpdate?: ID
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>
  isAllSelected: boolean
  disabled: boolean
  isShowAddFreightModal: boolean
  handleAddFreightShow: () => void
  handleAddFreightHide: () => void
  isLoadingAddFreight: boolean
  handleEnableLoaderAddFreight: () => void
  handleDisableLoaderAddFreight: () => void
  selectedItemForEdit: Freight
  setSelectedItemForEdit: Dispatch<SetStateAction<Freight>>
  isEditFreight: boolean
  handleEnableEditFreight: () => void
  handleDisableEditFreight: () => void
  selectedItemForDelete: Freight
  setSelectedItemForDelete: Dispatch<SetStateAction<Freight>>
  isShowDeleteFreightModal: boolean
  handleDeleteFreightShow: () => void
  handleDeleteFreightHide: () => void
  isLoadingDeleteFreight: boolean
  handleEnableLoaderDeleteFreight: () => void
  handleDisableLoaderDeleteFreight: () => void
}

export const initialListViewFreight: ListViewContextFreightProps = {
  selected: [],
  onSelect: () => { },
  onSelectAll: () => { },
  clearSelected: () => { },
  setItemIdForUpdate: () => { },
  isAllSelected: false,
  disabled: false,
  isShowAddFreightModal: false,
  handleAddFreightShow: () => { },
  handleAddFreightHide: () => { },
  isLoadingAddFreight: false,
  handleEnableLoaderAddFreight: () => { },
  handleDisableLoaderAddFreight: () => { },
  selectedItemForEdit: {},
  setSelectedItemForEdit: () => { },
  isEditFreight: false,
  handleEnableEditFreight: () => { },
  handleDisableEditFreight: () => { },
  selectedItemForDelete: {},
  setSelectedItemForDelete: () => { },
  isShowDeleteFreightModal: false,
  handleDeleteFreightShow: () => { },
  handleDeleteFreightHide: () => { },
  isLoadingDeleteFreight: false,
  handleEnableLoaderDeleteFreight: () => { },
  handleDisableLoaderDeleteFreight: () => { },
}

export type ListViewContextGradegroupProps = {
  selected: Array<ID>
  onSelect: (selectedId: ID) => void
  onSelectAll: () => void
  clearSelected: () => void
  itemIdForUpdate?: ID
  setItemIdForUpdate: Dispatch<SetStateAction<ID>>
  isAllSelected: boolean
  disabled: boolean
  isShowAddGradegroupModal: boolean
  handleAddGradegroupShow: () => void
  handleAddGradegroupHide: () => void
  isLoadingAddGradegroup: boolean
  handleEnableLoaderAddGradegroup: () => void
  handleDisableLoaderAddGradegroup: () => void

  selectedItemForEditGrade: Grade
  setSelectedItemForEditGrade: Dispatch<SetStateAction<Grade>>
  isEditGradegroup: boolean
  handleEnableEditGradegroup: () => void
  handleDisableEditGradegroup: () => void
  
}

export const initialListViewGradegroup: ListViewContextGradegroupProps = {
  selected: [],
  onSelect: () => { },
  onSelectAll: () => { },
  clearSelected: () => { },
  setItemIdForUpdate: () => { },
  isAllSelected: false,
  disabled: false,
  isShowAddGradegroupModal: false,
  handleAddGradegroupShow: () => { },
  handleAddGradegroupHide: () => { },
  isLoadingAddGradegroup: false,
  handleEnableLoaderAddGradegroup: () => { },
  handleDisableLoaderAddGradegroup: () => { },

  selectedItemForEditGrade: {},
  setSelectedItemForEditGrade: () => { },
  isEditGradegroup: false,
  handleEnableEditGradegroup: () => { },
  handleDisableEditGradegroup: () => { },

}
