import { useEffect, useState } from 'react'
import { Col } from 'react-bootstrap'
import { Route, Routes, Outlet, Navigate, useNavigate, Link } from 'react-router-dom'
import { PageLink, PageTitle, useLayout } from '../../../_metronic/layout/core'
import AddOfferModal from './AddSellerOffer'
// import { SufalamsListWrapper } from '../sufalam-management/sufalams-list/SufalamsList'
import { OffersListWrapper } from './seller-offers-list/SellerOffersList'
import ViewOfferModal from './ViewSellerOffer'
import toast, { Toaster } from 'react-hot-toast'
import leaderRoles from '../../utils/leaderRoles'
import adminRoles from '../../utils/adminRoles'
import axios from 'axios'
import { SERVER_URL } from '../../ServerConfig'
import download from "downloadjs"
import { KTSVG } from '../../../_metronic/helpers'
import marketingTeamRoles from '../../utils/marketingTeamRoles'
import {QueryRequestProvider} from './seller-offers-list/core/QueryRequestProvider'
import {QueryResponseProvider} from './seller-offers-list/core/QueryResponseProvider'

const usersBreadcrumbs: Array<PageLink> = [
  {
    title: 'Offer Management',
    path: 'offer/offers',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const SellerOffersPage = () => {
  const { config } = useLayout()
  const [ShowOfferModal, setShowOfferModal] = useState<boolean>(false)
  const [message, setMessge] = useState<any>({
    type: '',
    message: '',
    show: false,
  })

  const getExportCall = async () =>{
    await axios
      .get(`${SERVER_URL}/offer/export`, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("auth-token")}`
        },
        responseType: "blob",
      })
      .then((res:any) => {
        const content = res.headers["content-type"];
        download(res.data, + "exportfile.xls", content);
      })
      .catch((error:any) => {
        console.log("error",error)
      });
  };

  useEffect(() => {
    if (message.message) successToast(message.message)
  }, [message])

  // const [ShowViewOfferModal, setShowViewOfferModal] = useState<boolean>(false)
  const successToast = (message: string) => toast.success(message)


  return (
    <>
      <Routes>
        <Route element={<Outlet />}>
          <Route
            path='seller-offer'
            element={
              <AddOfferModal
                show={ShowOfferModal}
                handleClose={() => setShowOfferModal(false)}
                message={message}
                setMessge={setMessge}
              />
            }
          />
        </Route>
        <Route index element={<Navigate to='/seller-offer' />} />
      </Routes>
      <Toaster
        position='top-center'
        toastOptions={{
          duration: 3000,
          // role: 'status',
          // ariaLive: 'polite',
          style: {
            background: 'green',
            color: 'whitesmoke',
          },
          iconTheme: {
            primary: 'red',
            secondary: 'white',
          },
        }}
      />
    </>
  )
}

export default SellerOffersPage
