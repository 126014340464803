export default function userRoles(){
    const objUser = JSON.parse(localStorage.getItem("objUser"))
    if(objUser){
        if(objUser?.role_id === 1) return 1
        else if(objUser?.role_id === 2) return 2
        else if(objUser?.role_id === 3) return 3
        else if(objUser?.role_id === 4) return 4
        else return false
    }
    else return false
}