import axios, {AxiosResponse} from 'axios'
import {ID, Response} from '../../../../../_metronic/helpers'
import {Quotation, QuotationsQueryResponse} from './_models'
import {Offer} from "../../../offer-management/offers-list/core/_models";

const API_URL_NEW = process.env.REACT_APP_API_URL_NEW

const QUOTATION_URL = `${API_URL_NEW}/quotation`
// const GET_QUOTATIONS_URL = `${API_URL}/users/query`

const GET_QUOTATIONS_URL = `${API_URL_NEW}/quotation`
const ALL_INQUIRY_URL = `${API_URL_NEW}/inquiry`
const CREATE_QUOTATION_URL = `${API_URL_NEW}/quotation`

const GET_ALL_STATE = `${API_URL_NEW}/state/allStates`

const GET_TRANSPORT_URL = `${API_URL_NEW}/transport/?perPage=50&page=1`

const ALL_LOCATION_URL = `${API_URL_NEW}/location`
const SENDQUOTATIONEMAIL = `${API_URL_NEW}/quotation/send/so-mail`
const ALL_LOCATION_BY_SORT_URL = `${API_URL_NEW}/location/allLocations?sort=name&order=asc`

const getQuotation = (query: string): Promise<QuotationsQueryResponse> => {
  return axios
    .get(`${GET_QUOTATIONS_URL}?${query}`)
    .then((d: AxiosResponse<QuotationsQueryResponse>) => d.data)
}

const getQuotationById = (id: ID): Promise<Quotation | undefined> => {
  return axios
    .get(`${GET_QUOTATIONS_URL}/${id}`)
    .then((response: AxiosResponse<Response<Quotation>>) => response.data)
    .then((response: Response<Quotation>) => response.data)
}


const getQuotationByIdNew = (id:string): Promise<any> => {
  return axios
      .get(`${GET_QUOTATIONS_URL}/${id}`)
      .then((response: AxiosResponse<Response<any>>) => response.data)
}

const createQuotationNew = (quotation: any): Promise<any | undefined> => {
  return axios
      .post(CREATE_QUOTATION_URL, quotation)
      .then((response: AxiosResponse<Response<any>>) => response.data)
      .then((response: Response<any>) => response)
}
const createQuotation = (quotation: Quotation): Promise<Quotation | undefined> => {
  return axios
    .put(QUOTATION_URL, quotation)
    .then((response: AxiosResponse<Response<Quotation>>) => response.data)
    .then((response: Response<Quotation>) => response.data)
}

const updateQuotation = (quotation: Quotation): Promise<Quotation | undefined> => {
  return axios
    .post(`${QUOTATION_URL}/${quotation.id}`, quotation)
    .then((response: AxiosResponse<Response<Quotation>>) => response.data)
    .then((response: Response<Quotation>) => response.data)
}

const updateQuotationNew = (id:string ,quotation: any): Promise<any | undefined> => {
  return axios
      .patch(`${QUOTATION_URL}/${id}`, quotation)
      .then((response: AxiosResponse<Response<any>>) => response.data)
      .then((response: Response<any>) => response)
}

const deleteQuotation = (id:string): Promise<any> => {
  return axios
      .delete(`${GET_QUOTATIONS_URL}/${id}`)
      .then((response: AxiosResponse<Response<any>>) => response.data)
}

const getTranspotationList = (): Promise<any> => {
  return axios
      .get(`${GET_TRANSPORT_URL}`)
      .then((response: AxiosResponse<Response<any>>) => response.data)
}

const deleteSelectedQuotations = (quotationIds: Array<ID>): Promise<void> => {
  const requests = quotationIds.map((id) => axios.delete(`${QUOTATION_URL}/${id}`))
  return axios.all(requests).then(() => {})
}
const getAllInquiryList = (): Promise<any> => {
  // let state = `?search=${search}&perPage=10&page=1`
  return axios.get(`${ALL_INQUIRY_URL}`).then((d: AxiosResponse<any>) => d.data)

}
const getAllInquiryListSearch = (search:any, openInquiry :any): Promise<any> => {
  
  let state = `?inquiry_number=${search}&perPage=10&page=1&openInquiry=${openInquiry ? 1 :""}`
  return axios.get(`${ALL_INQUIRY_URL}${state}`).then((d: AxiosResponse<any>) => d.data)
}
const getAllStateList = (): Promise<any> => {
  return axios.get(`${GET_ALL_STATE}`).then((d: AxiosResponse<any>) => d.data)
}

const getDistrictLocation = (id:any): Promise<any> => {
  let state={
    state_name:id
  }
  // @ts-ignore
  return axios.get(`${ALL_LOCATION_URL}`,state).then((d: AxiosResponse<any>) => d.data)
}

const getDistrictLocationAll = (): Promise<any> => {
  // let state = {
  //   state_name: id,
  // }
  // @ts-ignore
  return axios.get(`${ALL_LOCATION_URL}/allLocations`).then((d: AxiosResponse<any>) => d.data)
}

const getDistrictLocationByID = (dId:any): Promise<any> => {
  // let state = {
  //   state_name: id,
  // }
  // @ts-ignore
  return axios.get(`${API_URL_NEW}/location?state_name=${dId}&search=&perPage=1000&page=1`).then((d: AxiosResponse<any>) => d.data)
}

const sendEmailQuotation = (quotationId: any): Promise<any | undefined> => {
  return axios
    .get(`${SENDQUOTATIONEMAIL}/${quotationId}`)
    .then((response: AxiosResponse<Response<any>>) => response.data)
}

const getLocationList = (): Promise<any> => {
  return axios.get(`${ALL_LOCATION_BY_SORT_URL}`).then((d: AxiosResponse<any>) => d.data)
}

export {
  getQuotation,
  deleteQuotation,
  deleteSelectedQuotations,
  getQuotationById,
  createQuotation,
  updateQuotation,
  getQuotationByIdNew,
  getAllInquiryList,
  createQuotationNew,
  getAllStateList,
  updateQuotationNew,
  getTranspotationList,
  getDistrictLocation,
  getAllInquiryListSearch,
  getDistrictLocationAll,
  getDistrictLocationByID,
  sendEmailQuotation,
  getLocationList
}
