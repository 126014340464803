import {createRoot} from 'react-dom/client'
// Axios
import axios from 'axios'
import {Chart} from 'chart.js'
import {QueryClient, QueryClientProvider} from 'react-query'
// Apps
import {MetronicI18nProvider} from './_metronic/i18n/Metronici18n'
/**
 * TIP: Replace this style import with rtl styles to enable rtl mode
 *
 * import './_metronic/assets/css/style.rtl.css'
 **/
import './_metronic/assets/sass/style.scss'
import './_metronic/assets/sass/plugins.scss'
import './_metronic/assets/sass/style.react.scss'
import './_metronic/assets/sass/custom.scss'
import {AppRoutes} from './app/routing/AppRoutes'
import {AuthProvider, setupAxios} from './app/modules/auth'
import { SellerAllRoutes } from './app/routing/SellerAllRoutes'
/**
 * Creates `axios-mock-adapter` instance for provided `axios` instance, add
 * basic Metronic mocks and returns it.
 *
 * @see https://github.com/ctimmerm/axios-mock-adapter
 */
/**
 * Inject Metronic interceptors for axios.
 *
 * @see https://github.com/axios/axios#interceptors
 */

axios.interceptors.response.use((response:any) => {
  console.log("responseresponse",response)
  if (response?.data?.responseStatus == 401) {
    localStorage.clear()
    setTimeout(()=>{
      window.location.reload()
    },2000)
  }
  // else if (response?.data?.responseStatus == 403) {
  //   window.location.replace('/error/403')
  // }
  return response;
}, (error) => {
  return Promise.reject(error.message);
});

setupAxios(axios)
// Chart.register(...registerables)

// if (process.env.REACT_APP_ENVIRONMENT == 'production') {
//   const noop = () => {}
//   window.console.log = noop
// }

const queryClient = new QueryClient()
const container = document.getElementById('root')
if (container) {
  createRoot(container).render(
    <QueryClientProvider client={queryClient}>
      <MetronicI18nProvider>
        <AuthProvider>
          {process.env.REACT_APP_BUILD_TYPE === "1" ? <AppRoutes /> : <SellerAllRoutes/>}
        </AuthProvider>
      </MetronicI18nProvider>
    </QueryClientProvider>
  )
}
